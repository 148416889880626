import React from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { Switch, Route, Redirect } from "react-router-dom";

import Topbar from "./Topbar";
import IssuedCertificates from "../../../pages/IssuedCertificates";
import CreateCertificate from "../../../pages/CreateCertificate";
import Users from "../../../pages/Users";
import ReviewCertificate from "../../../pages/ReviewCertificate";
import RevokeCertificate from "../../../pages/RevokeCertificate";
import Revoked from "../../../pages/Revoked";
import Login from "../../../pages/Login";

const disp = ({ sidebarIsOpen, toggleSidebar }) => (
  <Switch>
    <>
      <Container
        fluid
        className={classNames("content", { "is-open": sidebarIsOpen })}
      >

        <div>
          <Topbar toggleSidebar={toggleSidebar} />

          <Route exact path="/" render={() => {
            return (
              <Redirect to="/issuedCertificates" />

            )
          }} />
          <Route exact path="/issuedCertificates" component={IssuedCertificates} />
          <Route exact path="/Users" component={Users} />

          <Route exact path="/contact" component={() => "Contact"} />
          <Route exact path="/createCertificate" component={CreateCertificate} />
          <Route exact path="/reviewCertificate" component={ReviewCertificate} />
          <Route exact path="/revokeCertificate" component={RevokeCertificate} />
          <Route exact path="/revoked" component={Revoked} />
          <Route exact path="/Page-1" component={() => "Page-1"} />
          <Route exact path="/Page-2" component={() => "Page-2"} />
          <Route exact path="/page-1" component={() => "page-1"} />
          <Route exact path="/page-2" component={() => "page-2"} />
          <Route exact path="/page-3" component={() => "page-3"} />
          <Route exact path="/page-4" component={() => "page-4"} />
        </div>
      </Container>
    </>
  </Switch>

);

export default disp;
