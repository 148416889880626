import React, { useState, useMemo, useEffect } from "react";
import request from "../Server";
import { Link } from "react-router-dom";
import Table from "./Table";
import styled from "styled-components";
import * as GeneralFunctions from "../GeneralFunctions";
import ReactTable, { useTable } from "react-table";
import { Modal, ProgressBar, Card } from "react-bootstrap";
import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Progress,
} from "reactstrap";
import Moment from "react-moment";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/style.scss";
import IssuedCertificates from "./IssuedCertificates";
import PageSpinner from "../components/PageSpinner";

class RevokeCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      accountAddress: null,
      pageData: [],
      loadingData: true,
      searchInput: "",
      show: false,
      view: false,
      signedBy: null,
      msgParams: null,
      selectedData: [],
    };
  }

  filterById(jsonObject, id) {
    return jsonObject.filter(function (jsonObject) {
      return jsonObject["_id"] == id;
    })[0];
  }
  showView = (event) => {
    this.setState({ view: true });
    this.handleShow(event);
  };
  handleClose = () => {
    this.setState({ show: false, view: false });
  };
  handleShow = (event) => {
    const selectedObject = this.filterById(this.state.data, event.target.value);
    this.setState({ show: true, selectedData: selectedObject });
  };

  componentDidMount() {
    const getData = async () => {
      this.setState({ loadingData: true });
      const base = "admin/certificate/search";
      const response = await request({
        url: base,
        method: "POST",
        data: { revokeRequest: true },
      });

      const account = GeneralFunctions.getWeb3Account();
      const address = await account;
      this.setState({ accountAddress: address });
      const certificates = response.certificates.filter(
        (certificate) => certificate.status !== "revoked"
      );
      this.setState({
        data: JSON.parse(JSON.stringify(certificates)),
        pageData: JSON.parse(JSON.stringify(certificates)),
        loadingData: false,
      });
    };
    if (this.state.loadingData) {
      getData();
    }
  }

  handleSign = async (event) => {
    const obj = JSON.parse(event.target.value);
    const msgParams = [
      {
        type: "string", // Any valid solidity type
        name: "To Revoke a Issued Certifcate with TX.ID:", // Any string label you want
        value: "oxbq3ejq32uaesa32aje", // The value to sign
      },
      {
        type: "string", // Any valid solidity type
        name: "NAME:", // Any string label you want
        value: obj.professionalName, // The value to sign
      },
      {
        type: "string",
        name: "ID:",
        value: obj._id,
      },
      {
        type: "string",
        name: "Type of Certificate:",
        value: "CED",
      },
    ];
    const res = GeneralFunctions.signMsg(msgParams, this.state.accountAddress);
    if (res != null) {
      await this.signCertificate(obj._id, await res);
      window.location.reload(false);
    }
  };

  signCertificate = async (certificateID, signedBy) => {
    const user = GeneralFunctions.getAuthUser();
    const base = `/admin/signCertificate`;
    try {
      const response = await request({
        url: base,
        method: "POST",
        data: { certificateId: certificateID, signedBy: signedBy, user: user },
      });
    } catch (error) {
      alert(error);
      throw Error("ERRROR");
    }
  };

  globalSearch = (searchInput) => {
    let filteredData = this.state.pageData.filter((value) => {
      return (
        value.professionalName
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.issuerId.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  render() {
    const columns = [
      {
        Header: "Name",
        accessor: "professionalName",
      },
      {
        Header: "IssuerId",
        accessor: "issuerId",
      },
      {
        Header: "Action",
        accessor: "_id",
        Cell: ({ cell }) => {
          return (
            <>
              <Button
                type="button"
                value={cell.row.values._id}
                color="danger"
                style={{ marginRight: 15 }}
                onClick={this.handleShow}
                className="btn-sm"
              >
                Revoke
              </Button>
              <Button
                type="button"
                color="info"
                value={cell.row.values._id}
                onClick={this.showView}
                className="btn-sm"
              >
                View
              </Button>
            </>
          );
        },
      },
      {
        Header: "Signed By",
        accessor: "signedIssuerIds",
        Cell: ({ cell }) => {
          const signs = cell.row.values.signedIssuerIds;
          let numberOfSigns = 0;
          if (signs !== undefined) numberOfSigns = signs.length;
          return (
            <>
              <div>
                <span> {numberOfSigns}/3 Signed </span>
                <ProgressBar variant="warning" now={numberOfSigns * 33} />
              </div>
            </>
          );
        },
      },
    ];
    return (
      <>
        <div>
          <Row className="mt-3">
            <Col xs={12} sm={12}>
              <Card mt="5" className="card-main">
              <PageSpinner showLoader={this.state.loadingData} />
                <Card.Body>
                  <Card.Title className="text-center">
                    Revoke Certificates
                  </Card.Title>
                  <Row className="mb-4">
                    <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={5}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="btn btn-secondary">
                            <span>
                              <FontAwesomeIcon
                                icon={faSearch}
                                className="text-white"
                              />
                            </span>
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          size="large"
                          name="searchInput"
                          onChange={(e) => {
                            if (e.target.value)
                              this.globalSearch(e.target.value);
                            else this.setState({ data: this.state.pageData });
                          }}
                          label="Search"
                          placeholder="Search Certificate No., Name, ID ..."
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Table columns={columns} data={this.state.data} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Sign to revoke Certificate </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Name: {this.state.selectedData.professionalName}
                <br />
                Course: {this.state.selectedData.field}
                <br />
                Description: {this.state.selectedData.description}
                <br />
                Provider: {this.state.selectedData.provider}
                <br />
                Issue Date:{" "}
                {this.state.selectedData.issueDate && (
                  <Moment format="MMM DD, YYYY">
                    {this.state.selectedData.issueDate}
                  </Moment>
                )}
                <br />
                Expiration Date (optional):{" "}
                {this.state.selectedData.expiryDate && (
                  <Moment format="MMM DD, YYYY">
                    {this.state.selectedData.expiryDate}
                  </Moment>
                )}
                <br />
                CECs: {this.state.selectedData.CECs}
              </p>
              <label>Reason: {this.state.selectedData.revokeReason}</label>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="danger"
                value={JSON.stringify(this.state.selectedData)}
                style={{ visibility: this.state.view ? "hidden" : "visible" }}
                onClick={this.handleSign}
              >
                Revoke Certificate
              </Button>
              <Button color="secondary" onClick={this.handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default RevokeCertificate;
