import React, { useState, useMemo, useEffect } from "react";
import request from "../Server";
import { Link } from "react-router-dom";
import Table from "./Table";
import styled from "styled-components";
import * as GeneralFunctions from "../GeneralFunctions";
import ReactTable, { useTable } from "react-table";
import { Modal, ProgressBar, Card } from "react-bootstrap";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Popover,
  PopoverHeader,
  PopoverBody,
  Progress,
  Alert,
  Badge,
  IconButton,
} from "reactstrap";
import Moment from "react-moment";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/modal.css";
import "../styles/style.scss";
import ToolTip from "react-tooltip";
import flag from "../assets/img/flag_red.png";
import PageSpinner from "../components/PageSpinner";

class ReviewCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      accountAddress: null,
      pageData: [],
      showLoader: true,
      loadingData: true,
      searchInput: "",
      show: false,
      signedBy: null,
      msgParams: null,
      selectedData: [],
      anchor: null,
      view: false,
      reportShow: false,
      confirmDelete: false,
      reason: "",
    };
  }

  filterById(jsonObject, id) {
    return jsonObject.filter(function (jsonObject) {
      return jsonObject["_id"] == id;
    })[0];
  }
  filterBySign(jsonObject, id) {
    return jsonObject.filter(function (jsonObject) {
      return jsonObject["signedIssuerIds"].length == 0;
    });
  }

  handleClose = () => {
    this.setState({ show: false, confirmDelete: false, view: false });
  };
  reportClose = () => {
    this.setState({ reportShow: false });
  };
  showView = (event) => {
    this.setState({ view: true });
    this.handleShow(event);
  };
  handleShow = async (event) => {
    const account = GeneralFunctions.getWeb3Account();
    const selectedObject = this.filterById(this.state.data, event.target.value);
    this.setState({ show: true, selectedData: selectedObject });
    this.setState({ accountAddress: await account });
  };
  reportModal = async (id) => {
    const selectedObject = this.filterById(this.state.data, id);
    this.setState({ reportShow: true, selectedData: selectedObject });
  };
  showDelete = (id) => {
    const selectedObject = this.filterById(this.state.data, id);
    this.setState({
      confirmDelete: true,
      show: true,
      selectedData: selectedObject,
    });
  };
  handleDelete = async (certificateID) => {
    const user = GeneralFunctions.getAuthUser();
    const base = "admin/deleteCertificate";
    const response = await request({
      url: base,
      method: "POST",
      data: { certificateId: certificateID, user: user },
    });
    if (response) {
      window.location.reload(false);
    }
    this.setState({ confirmDelete: false, show: false });
  };
  handleReport = async (event) => {
    const certificateID = event.target.value;
    this.setState({ showLoader: true });
    const user = GeneralFunctions.getAuthUser();
    const base = "admin/reportCertificate";
    const response = await request({
      url: base,
      method: "POST",
      data: {
        certificateId: certificateID,
        user: user,
        report: this.state.reason,
      },
    });
    if (response) {
      window.location.reload(false);
    }
  };

  getData = async () => {
    const user = GeneralFunctions.getAuthUser();
    this.setState({ showLoader: true });
    const base = "admin/certificate/search";
    const response = await request({
      url: base,
      method: "POST",
      data: { status: "created" },
    });
    const dataCheck = this.filterBySign(
      JSON.parse(JSON.stringify(response.certificates))
    );
    if (dataCheck.length > 0) {
      let reload = false;
      dataCheck.forEach(async (element) => {
        const base = "admin/deleteCertificate";
        const response = await request({
          url: base,
          method: "POST",
          data: { certificateId: element._id, user: user },
        });
        if (response) {
          reload = true;
        }
      });
      if (reload) {
        window.location.reload(false);
      }
    } else {
      this.setState({
        data: JSON.parse(JSON.stringify(response.certificates)),
        pageData: JSON.parse(JSON.stringify(response.certificates)),
        showLoader: false,
      });
    }
  };

  componentDidMount() {
    this.getData();
  }

  handleSign = async (event) => {
    const obj = JSON.parse(event.target.value);
    const msgParams = [
      {
        type: "string", // Any valid solidity type
        name: "NAME:", // Any string label you want
        value: obj.professionalName, // The value to sign
      },
      {
        type: "string",
        name: "Field:",
        value: obj.field,
      },
      {
        type: "string",
        name: "Type of Certificate:",
        value: "CED",
      },
      {
        type: "string",
        name: "Provider:",
        value: obj.provider,
      },
      {
        type: "string",
        name: "Issue Date:",
        value: obj.issueDate,
      },
    ];

    const res = GeneralFunctions.signMsg(msgParams, this.state.accountAddress);
    if (res != null) {
      await this.signCertificate(obj._id, await res);
    }
  };

  signCertificate = async (certificateID, signedBy) => {
    const user = GeneralFunctions.getAuthUser();
    const base = `/admin/signCertificate`;
    try {
      const response = await request({
        url: base,
        method: "POST",
        data: { certificateId: certificateID, signedBy: signedBy, user: user },
      });
      if (response) {
        window.location.reload(false);
      }
    } catch (error) {
      alert(error);
      throw Error("ERRROR");
    }
  };

  globalSearch = (searchInput) => {
    let filteredData = this.state.pageData.filter((value) => {
      return (
        value.professionalName
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.issuerId.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  render() {
    const columns = [
      {
        Header: "Name",
        accessor: "professionalName",
      },
      {
        Header: "Created By",
        accessor: "issuerId",
      },
      {
        Header: "Action",
        accessor: "_id",
        Cell: ({ cell }) => {
          const selectedObject = this.filterById(
            this.state.data,
            cell.row.values._id
          );
          const reportLength = selectedObject?.reports?.length;
          const signedIssuerIdsLength = selectedObject?.signedIssuerIds?.length;
          return (
            <>
              {GeneralFunctions.getAuthUser().email !==
                cell.row.values.issuerId ? (
                <>
                  <Button
                    type="button"
                    value={cell.row.values._id}
                    color="warning"
                    style={{ marginRight: 15 }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.reportModal(cell.row.values._id);
                    }}
                    className="btn-sm"
                  >
                    Report
                    <Badge pill>
                      <text>{reportLength}</text>
                    </Badge>
                  </Button>

                  <Button
                    type="button"
                    value={cell.row.values._id}
                    color="primary"
                    style={{ marginRight: 15 }}
                    onClick={this.handleShow}
                    className="btn-sm"
                    disabled={signedIssuerIdsLength >= 3}
                  >
                    Sign
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    value={cell.row.values._id}
                    color="warning"
                    style={{ marginRight: 15 }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.reportModal(cell.row.values._id);
                    }}
                    className="btn-sm"
                  >
                    Report
                    <Badge pill>
                      <text>{reportLength}</text>
                    </Badge>
                  </Button>
                  <Button
                    type="button"
                    value={cell.row.values._id}
                    color="danger"
                    style={{ marginRight: 15 }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.showDelete(cell.row.values._id);
                    }}
                    className="btn-sm"
                  >
                    Delete
                  </Button>
                </>
              )}
              <Button
                type="button"
                value={cell.row.values._id}
                onClick={this.showView}
                className="btn-sm"
              >
                View
              </Button>
            </>
          );
        },
      },
      {
        Header: "Signed By",
        accessor: "signedIssuerIds",
        Cell: ({ cell }) => {
          const signs = cell.row.values.signedIssuerIds;
          let numberOfSigns = 0;
          if (signs !== undefined) {
            numberOfSigns = signs.length;
          }

          const tipId = Math.random().toString(36).substr(2, 5);
          return (
            <>
              <div>
                <span data-tip={true} data-for={tipId}>
                  {numberOfSigns}/3 Signatures
                </span>
                {numberOfSigns > 0 ? (
                  <ToolTip place="right" type="info" id={tipId}>
                    {cell.row.values.signedIssuerIds}
                  </ToolTip>
                ) : (
                  <ToolTip place="right" type="info" id={tipId}>
                    No Signatures
                  </ToolTip>
                )}

                <ProgressBar variant="success" now={numberOfSigns * 33} />
              </div>

              <div></div>
            </>
          );
        },
      },
    ];

    return (
      <>
        <div>
          <Row className="mt-3">
            <Col xs={12} sm={12}>
              <Card mt="5" className="card-main">
                <PageSpinner showLoader={this.state.showLoader} />
                <Card.Body>
                  <Card.Title className="text-center">
                    Issue Certificates
                  </Card.Title>
                  <Row className="mb-4">
                    <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={5}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="btn btn-secondary">
                            <span>
                              <FontAwesomeIcon
                                icon={faSearch}
                                className="text-white"
                              />
                            </span>
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          size="large"
                          name="searchInput"
                          onChange={(e) => {
                            if (e.target.value)
                              this.globalSearch(e.target.value);
                            else this.setState({ data: this.state.pageData });
                          }}
                          label="Search"
                          placeholder="Search Certificate No., Name, ID ..."
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Table columns={columns} data={this.state.data} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              {this.state.confirmDelete ? (
                <>
                  <Modal.Title>Delete Certificate</Modal.Title>
                </>
              ) : this.state.view === true ? (
                <Modal.Title>Certificate Overview</Modal.Title>
              ) : (
                <Modal.Title>Sign Certificate</Modal.Title>
              )}
            </Modal.Header>
            <Modal.Body>
              {this.state.confirmDelete ? (
                <>Are you sure you want to Delete?</>
              ) : (
                <>
                  <p>
                    Name: {this.state.selectedData.professionalName}
                    <br />
                    Email: {this.state.selectedData.professionalEmail}
                    <br />
                    Phone: {this.state.selectedData.professionalPhone}
                    <br />
                    Address: {this.state.selectedData.professionalAddress}
                    <br />
                    Course: {this.state.selectedData.field}
                    <br />
                    Provider: {this.state.selectedData.provider}
                    <br />
                    Issue Date:{" "}
                    {this.state.selectedData.issueDate && (
                      <Moment format="MMM DD, YYYY">
                        {this.state.selectedData.issueDate}
                      </Moment>
                    )}
                    <br />
                    Expiration Date (optional):{" "}
                    {this.state.selectedData.expiryDate && (
                      <Moment format="MMM DD, YYYY">
                        {this.state.selectedData.expiryDate}
                      </Moment>
                    )}
                    <br />
                    CECs: {this.state.selectedData.CECs}
                  </p>
                </>
              )}
            </Modal.Body>

            <Modal.Footer>
              {this.state.confirmDelete ? (
                <>
                  <Button
                    color="danger"
                    value={this.state.selectedData._id}
                    onClick={() =>
                      this.handleDelete(this.state.selectedData._id)
                    }
                  >
                    Delete Certificate
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  value={JSON.stringify(this.state.selectedData)}
                  style={{ visibility: this.state.view ? "hidden" : "visible" }}
                  onClick={this.handleSign}
                >
                  Sign Certificate
                </Button>
              )}
              <Button color="secondary" onClick={this.handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.reportShow}
            onHide={this.reportClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Report Certificate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {GeneralFunctions.getAuthUser().email ===
                this.state.selectedData.issuerId ? (
                this.state.selectedData?.reports?.length > 0 ? (
                  <>
                    {this.state.selectedData.reports.map(
                      (report, idx) =>
                        report?.description && (
                          <>
                            <Alert key={idx} color="danger">
                              <b>{report?.reportedBy || "Admin"}</b> :{" "}
                              {report?.description}
                            </Alert>
                          </>
                        )
                    )}
                  </>
                ) : (
                  <></>
                )
              ) : (
                <>
                  <label for="w3review">Report Reason :</label>
                  <textarea
                    name="taname"
                    id="taid"
                    wrap="soft"
                    onChange={(val) =>
                      this.setState({ reason: val.currentTarget.value })
                    }
                  ></textarea>
                </>
              )}
            </Modal.Body>

            <Modal.Footer>
              {GeneralFunctions.getAuthUser().email !==
                this.state.selectedData.issuerId ? (
                <>
                  <Button
                    color="primary"
                    value={this.state.selectedData._id}
                    disabled={!this.state.reason}
                    onClick={this.handleReport}
                  >
                    Report Certificate
                  </Button>
                </>
              ) : (
                <></>
              )}
              <Button color="secondary" onClick={this.reportClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default ReviewCertificate;
