import { Content } from '../Layout';
import React from 'react';
import Header from './Header';
import Footer from './Footer'

const MainLayout = ({ children, ...restProps }) => (
  <>
  <Header />
  <Footer />
  </>
);

export default MainLayout;
