import GAListener from "./components/GAListener";
import { EmptyLayout, LayoutRoute, MainLayout } from "./components/Layout";
import Login from "./pages/Login";
import React from "react";
import componentQueries from "react-component-queries";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./styles/reduction.scss";
import "./styles/style.scss";
import "./styles/components/swiperStyle.css";

import { getAuthUser } from "./GeneralFunctions";
import Profile from "./pages/Profile";
import CreateCertificate from "./pages/CreateCertificate";
import IssuedCertificates from "./pages/IssuedCertificates";
import Users from "./pages/Users";
import ReviewCertificate from "./pages/ReviewCertificate";
import RevokeCertificate from "./pages/RevokeCertificate";
import Revoked from "./pages/Revoked";
const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={(props) => <Login {...props} />}
            />
            <MainLayout>
              <Route exact path="/" render={() => {
                return (
                  <Redirect to="/issuedCertificates" />

                )
              }} />
              <Route exact path="/issuedCertificates" component={IssuedCertificates} />
              <Route exact path="/Users" component={Users} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/createCertificate" component={CreateCertificate} />
              <Route exact path="/reviewCertificate" component={ReviewCertificate} />
              <Route exact path="/revokeCertificate" component={RevokeCertificate} />
              <Route exact path="/revoked" component={Revoked} />
              <Route exact path="/createCertificate" component={CreateCertificate} />
            </MainLayout>
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

export default componentQueries(query)(App);
