import React from 'react';
import { Redirect } from 'react-router-dom';
import { Card, Col, Row, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import logo200Image from '../assets/img/logo/logo.png';

import request from '../Server';
import * as GeneralFunctions from '../GeneralFunctions';
import * as EmailValidator from 'email-validator';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import PageSpinner from '../components/PageSpinner';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      email: '',
      password: '',
      emailValidater: '',
      passwordValidater: ''
    };
    this.authUser = null;
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(key, value) {
    let obj = {
      [key]: value
    };
    if (key && value) {
      if (key === 'email') {
        this.email = true;
      }
      if (key === 'password') {
        this.password = true;
      }
    }
    if (key === 'email') {
      if (obj.email) {
        let validEmail = EmailValidator.validate(obj.email);
        if (obj.email.length === 0) {
          this.setState({ emailValidater: "*Required" });
        } else if (!validEmail) {
          this.setState({ emailValidater: "*Please type valid email (test@email.com)." })
        } else {
          this.setState({ emailValidater: '' })
        }
      };
    }
    if (key === 'password') {
      if (!obj.password.length) {
        this.setState({ passwordValidater: "*Required" });
      } else {
        this.setState({ passwordValidater: '' })
      }
    }
    this.setState(obj);
  }

  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      showLoader: true
    });
    GeneralFunctions.setAuthorizationHeader('Basic ' + btoa(process.env.REACT_APP_SERVER_CLIENT_ID + ':' + process.env.REACT_APP_SERVER_CLIENT_SECRET));
    try {
      this.setState({ showLoader: true });
      let response = await request({
        url: 'auth/login',
        method: 'POST',
        data: {
          email: this.state.email,
          password: this.state.password,
          isAdmin: true
        }
      });
      if (response) {
        this.setState({ showLoader: false });
        GeneralFunctions.setAuthorizationHeader(response.tokenType + ' ' + response.token);
        GeneralFunctions.setAuthUser(response.user);
        GeneralFunctions.setUserPermission(response.permissions);
        GeneralFunctions.setUserRole(response.role);
        this.setState({
          showLoader: false
        });
        this.props.history.push('/issuedCertificates');
      }
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: 'error',
      });
    }
  }

  render() {
    this.authUser = GeneralFunctions.getAuthUser();
    const { email, password } = this.state;
    const disabled = this.state.email.length && this.state.password.length && this.state.emailValidater === '' && this.state.passwordValidater === '';
    return (
      this.authUser ? <Redirect to="/issuedCertificates"></Redirect>
        : <Row
          style={{
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <PageSpinner showLoader={this.state.showLoader} />
          <Col md={6} lg={4}>
            <Card body>
              <Form onSubmit={this.onSubmit}>
                <div className="text-center pb-4">
                  <img
                    src={logo200Image}
                    className="rounded"
                    style={{ width: 60, height: 60, cursor: 'pointer' }}
                    alt="logo"
                  />
                  <p>THE AB LAB<sup>Ⓡ</sup> dAPP</p>
                </div>
                <FormGroup>
                  <Label for="username">Email ID</Label>
                  <Input
                    type="email"
                    value={email}
                    name="user-mail"
                    id="user-mail"
                    className="has-input"
                    placeholder="Email ID"
                    onChange={(e) => this.onChange('email', e.target.value)}
                  />
                  {this.state.emailValidater ? <span className="float-right text-danger"><small>{this.state.emailValidater}</small></span> : null}
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    value={password}
                    type="Password"
                    name="user-pwd"
                    id="pwd"
                    className="has-input"
                    placeholder="Password"
                    minLength="6"
                    maxLength="15"
                    onChange={(e) => this.onChange('password', e.target.value)}
                  />
                  {(this.state.passwordValidater) ? <span className="float-right text-danger"><small>{this.state.passwordValidater}</small></span> : null}
                </FormGroup>
                <Button
                  variant="contained"
                  disabled={!disabled}
                  type="submit"
                  size="lg"
                  className="bg-gradient-theme-left border-0"
                  block
                >
                  Login
              </Button>
              </Form>
            </Card>
          </Col>
          <NotificationSystem
            dismissible={false}
            ref={notificationSystem =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </Row>
    );
  }
}

export default Login;
