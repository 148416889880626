import Web3 from "web3";
import sigUtil from "eth-sig-util";
import ethjs from "ethjs";
import Transaction from "ethereumjs-tx";

export const setAuthorizationHeader = (authorization) => {
  localStorage.setItem("authorization", authorization);
};

export const setAuthUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getAuthUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const setUserPermission = (permissions) => {
  localStorage.setItem("permissions", JSON.stringify(permissions));
};

export const getUserPermission = () => {
  return JSON.parse(localStorage.getItem("permissions"));
};

export const setUserRole = (role) => {
  localStorage.setItem("role", JSON.stringify(role));
};

export const getUserRole = () => {
  return JSON.parse(localStorage.getItem("role"));
};

export const stringTruncate = (value, limit) => {
  if (!value) {
    return value;
  }
  limit = limit ? limit : 50;
  return value.length > limit ? value.substring(0, limit) + "..." : value;
};

export const capitalize = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const clearLocalStorage = (refreshApp) => {
  localStorage.removeItem("user");
  localStorage.removeItem("authorization");
  localStorage.removeItem("permissions");
  if (refreshApp) {
    window.location.reload();
  }
};

export const getWeb3Account = async () => {
  if (window.ethereum) {
    //check if Metamask is installed
    try {
      await window.ethereum.enable();
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      return account;
    } catch (error) {
      return {
        connectedStatus: false,
        status: "🦊 Connect to Metamask using the button on the top right.",
      };
    }
  } else {
    return {
      connectedStatus: false,
      status:
        "🦊 You must install Metamask into your browser: https://metamask.io/download.html",
    };
  }
};

export const signMsg = async (msgParams, from) => {
  const params = [msgParams, from];
  const method = "eth_signTypedData";
  const result = await window.ethereum.request({
    method,
    params,
    from,
  });
  
  const recovered = sigUtil.recoverTypedSignature({
    data: msgParams,
    sig: result,
  });
  if (recovered === from) {
   
    return recovered;
  } else {
    alert("Failed to verify signer when comparing " + result + " to " + from);
    return null;
  }
  return null;
};


