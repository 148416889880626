import React from "react";
import FormData from "form-data";
import request from "../Server";
import Table from "./Table";
import * as GeneralFunctions from "../GeneralFunctions";
import {
  Form,
  FormLabel,
  FormControl,
  FormGroup,
  FormCheck,
  Card,
  Modal,
  ListGroup,
  Badge
} from "react-bootstrap";
import {
  Row,
  Col,
  Button,
  Alert,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  ImVideoCamera,
  ImProfile,
  ImImage,
  ImFilePdf,
  ImBubbles3,
  ImLink,
  ImLocation,
  ImCogs,
  ImAddressBook,
} from "react-icons/im";
import Moment from "react-moment";
import {
  faSearch,
  faUser,
  faTrash,
  faPen,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/style.scss";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import PageSpinner from "../components/PageSpinner";
import SearchAddress from "../components/Address";
import ToolTip from "react-tooltip";
import { Helpers } from "victory";
import { Formik } from "formik";

const initialSocialLinkValues = {
  socialLinkedInURL: "",
  socialFacebookURL: "",
  socialInstagramURL: "",
  socialTwitterURL: "",
  socialGooglePlusURL: "",
  socialPInterestURL: "",
};
const initialContactInfoValues = { email: "", phone: "", address: "", lat: 0, lng: 0 };
class IssuedCertificates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      accountAddress: null,
      pageData: [],
      searchInput: "",
      show: false,
      revokeShow: false,
      showVisitCount: false,
      showUploadedVideos: false,
      signedBy: null,
      msgParams: null,
      selectedData: [],
      selectedService: { service: "", index: "" },
      selectedTestimonial: { testimonial: "", index: "" },
      showLoader: false,
      uploadModal: false,
      uploadPhoto: false,
      uploadResume: false,
      uploadVideo: false,
      addTestimonial: false,
      updateTestimonial: false,
      addSocialMediaLink: false,
      addService: false,
      updateService: false,
      addAbout: false,
      addContactInfo: false,
      addFacilityLocation: false,
      currentFile: undefined,
      previewImage: undefined,
      progress: 0,
      totalVisitCount: 0,
      message: "",
      toggleCheck: "",
      errors: {},
      initialSocialLinkValues: initialSocialLinkValues,
      initialContactInfoValues: initialContactInfoValues,
      imageInfos: [],
      videoTitle: '',
    };
  }

  validateContactInfo = (values) => {
    const errors = {};
    const emailregex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneregex =
      /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/g;

    if (values.email && !emailregex.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (values.phone && !phoneregex.test(values.phone)) {
      errors.phone = "Invalid phone number";
    }

    if (!values.address) {
      errors.address = "Address required";
    }

    if (!values.phone) {
      errors.phone = "Phone required";
    }

    if (!values.email) {
      errors.email = "Email required";
    }

    return errors;
  };

  validateSocialLinks = (values) => {
    const errors = {};

    /*  if (!values.socialLinkedInURL) {
       errors.socialLinkedInURL = 'Required';
    }
    if (!values.socialFacebookURL) {
       errors.socialFacebookURL = 'Required';
    }
    if (!values.socialTwitterURL) {
       errors.socialTwitterURL = 'Required';
    }
    if (!values.socialGooglePlusURL) {
       errors.socialGooglePlusURL = 'Required';
    }
    if (!values.socialInstagramURL) {
      errors.socialInstagramURL = 'Required';
   }
   if (!values.socialPInterestURL) {
    errors.socialPInterestURL = 'Required';
   } */

    const linkedinregex = new RegExp(
      "^(http(s)?://)?(www.)?linkedin.com/(in|profile|pub)/([A-z 0-9 _ -]+)/?$"
    );

    const Facebookregex = new RegExp(
      "(?:(?:http|https)://)?(?:www.)?facebook.com/(?:(?:w)*#!/)?(?:pages/)?(?:[?w-]*/)?(?:profile.php?id=(?=d.*))?([w-]*)?"
    );

    const Googleregex = new RegExp(
      "/([+][\\w-_\\p{L}]+|[\\d]{21})(?:/)?(?:[\\w-_]+)?$/"
    );

    const Instagramregex = new RegExp(
      "^(http(s)?://)?(www.)?instagram.com/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:.(?!.))){0,28}(?:[A-Za-z0-9_]))?)/?$"
    );

    const Twitterinregex = new RegExp(
      "^(http(s)?://)?(www.)?twitter.com/[A-z 0-9 _]{1,15}/?$"
    );

    const Pintrestregex = new RegExp(
      "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
    );

    /*  if (
       values.socialLinkedInURL &&
       !linkedinregex.test(values.socialLinkedInURL)
     ) {
       errors.socialLinkedInURL = "Invalid Linkedin url";
     }
 
     if (
       values.socialFacebookURL &&
       !Facebookregex.test(values.socialFacebookURL)
     ) {
       errors.socialFacebookURL = "Invalid Facebook url";
     }
 
     if (
       values.socialGooglePlusURL &&
       !Googleregex.test(values.socialGooglePlusURL)
     ) {
       errors.socialGooglePlusURL = "Invalid Google Plus url";
     }
 
     if (
       values.socialInstagramURL &&
       !Instagramregex.test(values.socialInstagramURL)
     ) {
       errors.socialInstagramURL = "Invalid Instagram url";
     }
 
     if (
       values.socialTwitterURL &&
       !Twitterinregex.test(values.socialTwitterURL)
     ) {
       errors.socialTwitterURL = "Invalid Twitter url";
     }
 
     if (
       values.socialPInterestURL &&
       !Pintrestregex.test(values.socialPInterestURL)
     ) {
       errors.socialPInterestURL = "Invalid Pintrest url";
     } */

    return errors;
  };

  handleSubmitLinks = (values, setSubmitting) => {
    this.onSubmitSocialMediaLink(values);
  };

  handleSubmitContactInfo = (values, setSubmitting) => {
    this.onSubmitContactInfo(values);
  };

  filterById(jsonObject, id) {
    return jsonObject.filter(function (jsonObject) {
      return jsonObject["_id"] == id;
    })[0];
  }
  filterBySerial(jsonObject, id) {
    return jsonObject.filter(function (jsonObject) {
      return jsonObject["serialNumber"] == id;
    });
  }

  handleClose = () => {
    this.setState({
      show: false,
      uploadModal: false,
      revokeShow: false,
      addTestimonial: false,
      showVisitCount: false,
      showUploadedVideos: false,
    });
  };
  handleShow = (event) => {
    const selectedObject = this.filterById(this.state.data, event.target.value);
    this.setState({ show: true, selectedData: selectedObject });
  };

  handleVisitCount = (event) => {
    const selectedObject = this.filterById(this.state.data, event.target.value);
    const visitByMonths = [];
    let totalVisitCount = 0;
    for (const visit of selectedObject.visits) {
      const monthDate = visit.date.split('-')[0] + '-' + visit.date.split('-')[1];
      if (!visitByMonths.length) {
        visitByMonths.push({ date: monthDate, count: visit.count });
      } else {
        let findIndex = visitByMonths.findIndex((obj) => obj.date === monthDate);
        if (findIndex === -1) {
          visitByMonths.push({ date: monthDate, count: visit.count })
        } else {
          visitByMonths[findIndex].count += visit.count;
        }
      }
      totalVisitCount += visit.count;
    }
    selectedObject.visits = visitByMonths;
    this.setState({ showVisitCount: true, totalVisitCount: totalVisitCount, selectedData: selectedObject });
  };

  getIssuedCertificates = async () => {
    this.setState({ showLoader: true });
    const base = "admin/certificate/search";
    const response = await request({
      url: base,
      method: "POST",
      data: { status: "issued" },
    });

    this.setState({
      data: JSON.parse(JSON.stringify(response.certificates)),
      pageData: JSON.parse(JSON.stringify(response.certificates)),
      showLoader: false,
    });
  };

  componentDidMount() {
    this.getIssuedCertificates();
  }

  handleRevoke = (event) => {
    const selectedObject = this.filterById(this.state.data, event.target.value);
    this.setState({ revokeShow: true, selectedData: selectedObject });
  };

  revokeCertificate = async () => {
    const reason = document.getElementById("taid").value;
    const user = GeneralFunctions.getAuthUser();
    const base = `/admin/revokeRequest`;

    const msgParams = [
      {
        type: "string", // Any valid solidity type
        name: "NAME:", // Any string label you want
        value: this.state.selectedData.professionalName, // The value to sign
      },
      {
        type: "string",
        name: "Field:",
        value: this.state.selectedData.field,
      },

      {
        type: "string",
        name: "Type of Certificate:",
        value: "CED",
      },
      {
        type: "string",
        name: "Provider:",
        value: this.state.selectedData.provider,
      },
      {
        type: "string",
        name: "Issue Date:",
        value: this.state.selectedData.issueDate,
      },
    ];

    const account = GeneralFunctions.getWeb3Account();
    this.setState({
      accountAddress: await account,
      revokeShow: false,
    });

    const res = GeneralFunctions.signMsg(msgParams, this.state.accountAddress);
    if (res != null) {
      await this.signCertificate(this.state.selectedData?._id, await res);
      request({
        url: base,
        method: "POST",
        data: {
          certificateId: this.state.selectedData?._id,
          revokeReason: reason,
          user: user,
        },
      }).then(async (response) => {
        this.notificationSystem.addNotification({
          message: "Revoke Request Created",
          level: "success",
        });
      });
    }
  };

  signCertificate = async (certificateID, signedBy) => {
    const user = GeneralFunctions.getAuthUser();
    const base = `/admin/signCertificate`;
    try {
      const response = await request({
        url: base,
        method: "POST",
        data: { certificateId: certificateID, signedBy: signedBy, user: user },
      });
    } catch (error) {
      alert(error);
      throw Error("ERRROR");
    }
  };

  handleUpload = (event) => {
    const selectedObject = this.filterById(this.state.data, event.target.value);
    let professionalLat, professionalLng = 0;
    if (selectedObject.location) {
      professionalLat = selectedObject.location.coordinates[0];
      professionalLng = selectedObject.location.coordinates[1];
    }
    const newLinkedinFormState = initialSocialLinkValues;
    const newContactInfoFormState = initialContactInfoValues;
    newLinkedinFormState.socialFacebookURL =
      selectedObject.socialFacebookURL || "";
    newLinkedinFormState.socialLinkedInURL =
      selectedObject.socialLinkedInURL || "";
    newLinkedinFormState.socialInstagramURL =
      selectedObject.socialInstagramURL || "";
    newLinkedinFormState.socialGooglePlusURL =
      selectedObject.socialGooglePlusURL || "";
    newLinkedinFormState.socialTwitterURL =
      selectedObject.socialTwitterURL || "";
    newLinkedinFormState.socialPInterestURL =
      selectedObject.socialPInterestURL || "";
    newContactInfoFormState.email = selectedObject.professionalEmail || "";
    newContactInfoFormState.phone = selectedObject.professionalPhone || "";
    newContactInfoFormState.address = selectedObject.professionalAddress || "";
    newContactInfoFormState.lat = professionalLat || "";
    newContactInfoFormState.lng = professionalLng || "";
    selectedObject['showCertificateSection'] = selectedObject.showCertificateSection;
    selectedObject['showPhygitalKeySection'] = selectedObject.showPhygitalKeySection;
    selectedObject['showResumeSection'] = selectedObject.showResumeSection;;
    selectedObject['showVideoSection'] = selectedObject.showVideoSection;
    selectedObject['showTestimonialSection'] = selectedObject.showTestimonialSection;
    selectedObject['showSocialLinksSection'] = selectedObject.showSocialLinksSection;
    selectedObject['showServicesSection'] = selectedObject.showServicesSection;
    selectedObject['showFacilityLocationSection'] = selectedObject.showFacilityLocationSection;
    // selectedObject['showContactSection'] = selectedObject.showContactSection;

    this.setState({
      uploadModal: true,
      uploadPhoto: false,
      uploadResume: false,
      uploadVideo: false,
      currentFile: undefined,
      previewImage: undefined,
      show: true,
      videoTitle: '',
      selectedData: selectedObject,
      initialSocialLinkValues: newLinkedinFormState,
      initialContactInfoValues: newContactInfoFormState,
    });
  };

  selectFile = (event) => {
    this.setState({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
      progress: 0,
      message: "",
    });
  };

  upload = async (uploadType) => {
    this.setState({
      progress: 0,
      showLoader: true,
    });
    if (!this.state.currentFile) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: "No file selected.",
        level: "error",
      });
      return;
    }
    const base = `/admin/upload`;
    try {
      const formData = new FormData();
      formData.append("file", this.state.currentFile);
      await request({
        url: base,
        method: "POST",
        data: formData,
        serialNumber: this.state.selectedData.serialNumber,
        uploadType: uploadType,
        videoTitle: this.state.videoTitle,
      });
      this.setState({
        uploadModal: false,
        show: false,
        showLoader: false,
      });
      let message = "Photo uploaded successfully.";
      if (uploadType == "resume") message = "Resume uploaded successfully.";
      if (uploadType == "video") message = "Video uploaded successfully.";
      this.notificationSystem.addNotification({
        message: message,
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.notificationSystem.addNotification({
        message: error.message || "Error occurred during file upload.",
        level: "error",
      });
      this.setState({
        progress: 0,
        message: "",
        currentFile: undefined,
        showLoader: false,
      });
    }
  };

  onSubmitTestimonial = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    try {
      const body = {
        addTestimonial: true,
        testimonial: form.testimonial.value,
        certificateId: this.state.selectedData._id,
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      selectedData = Object.assign(selectedData, {
        testimonials: response.certificate.testimonials,
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, {
            testimonials: response.certificate.testimonials,
          });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        addTestimonial: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: "Testimonial added successfully.",
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onUpdateTestimonial = async (testimonial, index) => {
    this.setState({
      selectedTestimonial: { testimonial: testimonial, index: index },
      updateTestimonial: true,
    });
  };

  onSubmitUpdateTestimonial = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    try {
      const body = {
        updateTestimonial: true,
        testimonial: form.updateTestimonial.value,
        index: this.state.selectedTestimonial.index,
        certificateId: this.state.selectedData._id,
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      selectedData = Object.assign(selectedData, {
        testimonials: response.certificate.testimonials,
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, {
            testimonials: response.certificate.testimonials,
          });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        updateTestimonial: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: "Testimonial updated successfully.",
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onDeleteTestimonial = async (testimonial, index) => {
    if (!window.confirm("Are you sure want to remove this testimonial?")) {
      return false;
    }
    try {
      const body = {
        removeTestimonial: true,
        testimonial: testimonial,
        index: index,
        certificateId: this.state.selectedData._id,
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      selectedData = Object.assign(selectedData, {
        testimonials: response.certificate.testimonials,
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, {
            testimonials: response.certificate.testimonials,
          });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: "Testimonial removed successfully.",
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onSubmitSocialMediaLink = async (form) => {
    try {
      const body = {
        addSocialMediaLink: true,
        socialFacebookURL: form.socialFacebookURL,
        socialInstagramURL: form.socialInstagramURL,
        socialTwitterURL: form.socialTwitterURL,
        socialLinkedInURL: form.socialLinkedInURL,
        socialGooglePlusURL: form.socialGooglePlusURL,
        socialPInterestURL: form.socialPInterestURL,
        certificateId: this.state.selectedData._id,
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      selectedData = Object.assign(selectedData, {
        socialFacebookURL: response.certificate.socialFacebookURL,
        socialInstagramURL: response.certificate.socialInstagramURL,
        socialTwitterURL: response.certificate.socialTwitterURL,
        socialLinkedInURL: response.certificate.socialLinkedInURL,
        socialGooglePlusURL: response.certificate.socialGooglePlusURL,
        socialPInterestURL: response.certificate.socialPInterestURL,
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, {
            socialFacebookURL: response.certificate.socialFacebookURL,
            socialInstagramURL: response.certificate.socialInstagramURL,
            socialTwitterURL: response.certificate.socialTwitterURL,
            socialLinkedInURL: response.certificate.socialLinkedInURL,
            socialGooglePlusURL: response.certificate.socialGooglePlusURL,
            socialPInterestURL: response.certificate.socialPInterestURL,
          });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        addSocialMediaLink: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: "Social media link updated successfully.",
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onSubmitService = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    try {
      const body = {
        addService: true,
        service: form.service.value,
        certificateId: this.state.selectedData._id,
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      selectedData = Object.assign(selectedData, {
        services: response.certificate.services,
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, { services: response.certificate.services });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        addService: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: "Service added successfully.",
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onUpdateService = async (service, index) => {
    this.setState({
      selectedService: { service: service, index: index },
      updateService: true,
    });
  };

  onSubmitUpdateService = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    try {
      const body = {
        updateService: true,
        service: form.updateService.value,
        index: this.state.selectedService.index,
        certificateId: this.state.selectedData._id,
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      selectedData = Object.assign(selectedData, {
        services: response.certificate.services,
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, { services: response.certificate.services });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        updateService: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: "Service updated successfully.",
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onDeleteService = async (service, index) => {
    if (!window.confirm("Are you sure want to remove this service?")) {
      return false;
    }
    try {
      const body = {
        removeService: true,
        service: service,
        index: index,
        certificateId: this.state.selectedData._id,
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      selectedData = Object.assign(selectedData, {
        services: response.certificate.services,
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, { services: response.certificate.services });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: "Service removed successfully.",
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onSubmitFacilityLocation = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    try {
      const body = {
        addFacilityLocation: true,
        facilityName: form.facilityName.value,
        facilityAddress: form.facilityAddress.value,
        facilityCity: form.facilityCity.value,
        facilityState: form.facilityState.value,
        facilityZipCode: form.facilityZipCode.value,
        facilityCountry: form.facilityCountry.value,
        facilityWorkEmail: form.facilityWorkEmail.value,
        facilityPhoneNumber: form.facilityPhoneNumber.value,
        certificateId: this.state.selectedData._id,
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      selectedData = Object.assign(selectedData, {
        facilityName: response.certificate.facilityName,
        facilityAddress: response.certificate.facilityAddress,
        facilityCity: response.certificate.facilityCity,
        facilityState: response.certificate.facilityState,
        facilityZipCode: response.certificate.facilityZipCode,
        facilityCountry: response.certificate.facilityCountry,
        facilityWorkEmail: response.certificate.facilityWorkEmail,
        facilityPhoneNumber: response.certificate.facilityPhoneNumber,
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, {
            facilityName: response.certificate.facilityName,
            facilityAddress: response.certificate.facilityAddress,
            facilityCity: response.certificate.facilityCity,
            facilityState: response.certificate.facilityState,
            facilityZipCode: response.certificate.facilityZipCode,
            facilityCountry: response.certificate.facilityCountry,
            facilityWorkEmail: response.certificate.facilityWorkEmail,
            facilityPhoneNumber: response.certificate.facilityPhoneNumber,
          });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        addFacilityLocation: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: "Facility location updated successfully.",
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onSubmitAbout = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    try {
      const body = {
        addAbout: true,
        about: form.about.value,
        certificateId: this.state.selectedData._id,
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      selectedData = Object.assign(selectedData, {
        about: response.certificate.about,
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, { about: response.certificate.about });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        addService: false,
        addAbout: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: "About added successfully.",
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false, addAbout: true });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onSubmitContactInfo = async (form) => {
    try {
      /*  const contactNumberRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

      if(form.phone && !contactNumberRegex.test(form.phone)) {
        throw Error('Invalid contact number');
      }
 */
      const body = {
        addContactInfo: true,
        professionalEmail: form.email,
        professionalPhone: form.phone,
        professionalAddress: form.address,
        professionalLat: form.lat,
        professionalLng: form.lng,
        certificateId: this.state.selectedData._id,
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      let professionalLat, professionalLng = 0;
      if (response.certificate.location) {
        professionalLat = response.certificate.location.coordinates[0];
        professionalLng = response.certificate.location.coordinates[1];
      }
      selectedData = Object.assign(selectedData, {
        professionalEmail: response.certificate.professionalEmail,
        professionalPhone: response.certificate.professionalPhone,
        professionalAddress: response.certificate.professionalAddress,
        professionalLat: professionalLat,
        professionalLng: professionalLng,
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, {
            professionalEmail: response.certificate.professionalEmail,
            professionalPhone: response.certificate.professionalPhone,
            professionalAddress: response.certificate.professionalAddress,
            professionalLat: professionalLat,
            professionalLng: professionalLng,
          });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        addContactInfo: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: "Contact info updated successfully.",
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  onToggleSection = async (sectionName, sectionValue) => {
    console.log(sectionName, sectionValue);
    try {
      const body = {
        certificateId: this.state.selectedData._id,
        enableDisableSection: true,
        sectionName: sectionName,
        sectionValue: sectionValue
      };
      this.setState({ showLoader: true });
      const response = await request({
        url: "/admin/updateCertificateInfo",
        method: "POST",
        data: body,
      });
      let selectedData = this.state.selectedData;
      selectedData = Object.assign(selectedData, {
        [sectionName]: sectionValue
      });
      let data = this.state.data;
      data = data.map((obj) => {
        if (obj._id == selectedData._id) {
          obj = Object.assign(obj, { [sectionName]: sectionValue });
        }
        return obj;
      });
      this.setState({
        showLoader: false,
        addService: false,
        addAbout: false,
        data: data,
        selectedData: selectedData,
      });
      this.notificationSystem.addNotification({
        message: `Section ${sectionValue ? 'Shown' : 'Hidden'} successfully.`,
        level: "success",
      });
      this.getIssuedCertificates();
    } catch (error) {
      this.setState({ showLoader: false });
      this.notificationSystem.addNotification({
        message: error.message,
        level: "error",
      });
    }
  };

  resetDefault = async () => {
    const base = `/admin/default`;
    try {
      await request({
        url: base,
        method: "POST",
        data: {
          serialNumber: this.state.selectedData.serialNumber,
        },
      });
      this.setState({
        uploadModal: false,
        show: false,
      });
    } catch (error) {
      alert(error);
      this.setState({
        progress: 0,
        message: "Could not reset!",
        currentFile: undefined,
      });
    }
  };

  handleCheck = async (event) => {
    const sr = event.target.value;
    const selectedObject = this.filterBySerial(this.state.data, sr);
    let val = selectedObject[0].public;
    if (val.length > 0) {
      val = "";
    } else {
      val = "true";
    }

    const base = `/admin/toggleCertificateView`;
    try {
      const response = await request({
        url: base,
        method: "POST",
        data: {
          public: val,
          serialNumber: selectedObject[0].serialNumber,
        },
      });
      window.location.reload(false);
    } catch (error) {
      alert(error);
      throw Error("ERRROR");
    }
  };

  globalSearch = (searchInput) => {
    let filteredData = this.state.pageData.filter((value) => {
      return (
        value.professionalName
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.issuerId.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  getAcceptedTypes = () => {
    const { uploadPhoto, uploadResume, uploadVideo } = this.state;
    return uploadPhoto
      ? "image/*"
      : uploadResume
        ? "application/pdf"
        : "video/mp4";
  };

  render() {
    const columns = [
      {
        Header: "Public",
        accessor: "serialNumber",
        Cell: ({ cell }) => {
          const selectedObject = this.filterBySerial(
            this.state.data,
            cell.row.values.serialNumber
          );
          return (
            <>
              <input
                type="checkbox"
                value={cell.row.values.serialNumber}
                onClick={this.handleCheck}
                checked={selectedObject[0].public}
              />
            </>
          );
        },
      },
      {
        Header: "Name",
        accessor: "professionalName",
      },
      {
        Header: "Created By",
        accessor: "issuerId",
      },
      {
        Header: "Action",
        accessor: "_id",
        Cell: ({ cell }) => {
          const selectedObject = this.filterBySerial(
            this.state.data,
            cell.row.values.serialNumber
          );
          const tipId = Math.random().toString(36).substr(2, 5);
          const signedIssuerIdsLength = selectedObject?.signedIssuerIds?.length;
          return (
            <>
              {!selectedObject[0].public && (
                <ToolTip place="top" type="info" id={tipId}>
                  sdfsdf
                </ToolTip>
              )}
              <Button
                type="button"
                value={cell.row.values._id}
                color="success"
                style={{ marginRight: 15, marginTop: 5 }}
                onClick={this.handleShow}
                className="btn-md"
                disabled={!selectedObject[0].public}
              >
                View
              </Button>

              <Button
                type="button"
                color="danger"
                value={cell.row.values._id}
                style={{ marginRight: 15, marginTop: 5 }}
                onClick={this.handleRevoke}
                className="btn-md"
                disabled={
                  signedIssuerIdsLength >= 3 || selectedObject[0].public
                }
              >
                Revoke
              </Button>
              <Button
                type="button"
                color="info"
                style={{ marginTop: 5, marginRight: 15 }}
                value={cell.row.values._id}
                onClick={this.handleUpload}
                className="btn-md"
              >
                <FontAwesomeIcon icon={faUser} className="text-white" />
                Personalize
              </Button>
              <Button
                type="button"
                value={cell.row.values._id}
                style={{ marginTop: 5 }}
                color="warning"
                onClick={this.handleVisitCount}
                className="btn-md"
              >
                View Count
              </Button>
            </>
          );
        },
      },
    ];

    const { errors } = this.state;
    return (
      <>
        <div>
          <Row className="mt-3">
            <Col xs={12} sm={12}>
              <Card className="card-main">
                <PageSpinner showLoader={this.state.showLoader} />
                <NotificationSystem
                  dismissible={false}
                  ref={(notificationSystem) =>
                    (this.notificationSystem = notificationSystem)
                  }
                  style={NOTIFICATION_SYSTEM_STYLE}
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    Issued Certificates
                  </Card.Title>
                  <Row className="mb-4">
                    <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={5}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="btn btn-secondary">
                            <span>
                              <FontAwesomeIcon
                                icon={faSearch}
                                className="text-white"
                              />
                            </span>
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          size="large"
                          name="searchInput"
                          onChange={(e) => {
                            if (e.target.value)
                              this.globalSearch(e.target.value);
                            else this.setState({ data: this.state.pageData });
                          }}
                          label="Search"
                          placeholder="Search Certificate No., Name, ID ..."
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Table columns={columns} data={this.state.data} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Modal
            size="lg"
            show={this.state.show}
            onHide={() => this.handleClose()}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.uploadModal ? "Personalize" : "View"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.uploadModal ? (
                <>
                  {!this.state.uploadPhoto &&
                    !this.state.uploadResume &&
                    !this.state.uploadVideo && (
                      <>
                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div
                              className="d-flex align-items-center w-100 py-2 certificate"
                            >
                              <ImImage
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              Certificate
                            </div>
                          </div>
                          <div className="col-6">
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="certificate-switch"
                                  checked={this.state.selectedData.showCertificateSection}
                                  onChange={() => this.onToggleSection('showCertificateSection', !this.state.selectedData.showCertificateSection)}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div
                              className="d-flex align-items-center w-100 py-2 myphygital"
                            >
                              <ImProfile
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              My Phygital key
                            </div>
                          </div>
                          <div className="col-6">
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="phygital-key-switch"
                                  checked={this.state.selectedData.showPhygitalKeySection}
                                  onChange={() => this.onToggleSection('showPhygitalKeySection', !this.state.selectedData.showPhygitalKeySection)}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div

                              className="d-flex align-items-center w-100 py-2 photo"
                            >
                              <ImImage
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              Photo/Avatar
                            </div>
                          </div>
                          <div className="col-6" >
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Button
                                type="button"
                                color="info"
                                style={{ marginRight: 46 }}
                                onClick={() =>
                                  this.setState({ uploadPhoto: true })
                                }
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon={faUpload} className="text-white" style={{ marginRight: 5 }} />
                                Upload
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div
                              className=" d-flex align-items-center w-100 py-2 resume"
                            >
                              <ImFilePdf
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              Resume
                            </div>
                          </div>
                          <div className="col-6">
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Button
                                type="button"
                                color="info"
                                style={{ marginRight: 10 }}
                                onClick={() =>
                                  this.setState({ uploadResume: true })
                                }
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon={faUpload} className="text-white" style={{ marginRight: 5 }} />
                                Upload
                              </Button>
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="resume-switch"
                                  checked={this.state.selectedData.showResumeSection}
                                  onChange={() => this.onToggleSection('showResumeSection', !this.state.selectedData.showResumeSection)}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div
                              className="d-flex  align-items-center w-100 py-2 video"
                            >
                              <ImVideoCamera
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              Video
                            </div>
                          </div>
                          <div className="col-6">
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Button
                                type="button"
                                color="info"
                                style={{ marginRight: 10 }}
                                onClick={() =>
                                  this.setState({ uploadVideo: true })
                                }
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon={faUpload} className="text-white" style={{ marginRight: 5 }} />
                                Upload
                              </Button>
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="video-switch"
                                  checked={this.state.selectedData.showVideoSection}
                                  onChange={() => this.onToggleSection('showVideoSection', !this.state.selectedData.showVideoSection)}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>

                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div
                              className=" d-flex  align-items-center w-100 py-2 testimonial"
                            >
                              <ImBubbles3
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              Testimonials
                            </div>
                          </div>
                          <div className="col-6">
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Button
                                type="button"
                                color="info"
                                style={{ marginRight: 10 }}
                                onClick={() =>
                                  this.setState({ addTestimonial: true })
                                }
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon={faPen} className="text-white" style={{ marginRight: 5 }} />
                                Update
                              </Button>
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="testimonials-switch"
                                  checked={this.state.selectedData.showTestimonialSection}
                                  onChange={() => this.onToggleSection('showTestimonialSection', !this.state.selectedData.showTestimonialSection)}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div
                              className=" d-flex  align-items-center w-100 py-2 social-link"
                            >
                              <ImLink
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              Social media links
                            </div>
                          </div>
                          <div className="col-6">
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Button
                                type="button"
                                color="info"
                                style={{ marginRight: 10 }}
                                onClick={() =>
                                  this.setState({ addSocialMediaLink: true })
                                }
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon={faPen} className="text-white" style={{ marginRight: 5 }} />
                                Update
                              </Button>
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="social-links-switch"
                                  checked={this.state.selectedData.showSocialLinksSection}
                                  onChange={() => this.onToggleSection('showSocialLinksSection', !this.state.selectedData.showSocialLinksSection)}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div
                              className=" d-flex align-items-center w-100 py-2 services"
                            >
                              <ImCogs
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              Services
                            </div>
                          </div>
                          <div className="col-6">
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Button
                                type="button"
                                color="info"
                                style={{ marginRight: 10 }}
                                onClick={() =>
                                  this.setState({ addService: true })
                                }
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon={faPen} className="text-white" style={{ marginRight: 5 }} />
                                Update
                              </Button>
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="services-switch"
                                  checked={this.state.selectedData.showServicesSection}
                                  onChange={() => this.onToggleSection('showServicesSection', !this.state.selectedData.showServicesSection)}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div

                              className="d-flex align-items-center w-100 py-2 facility"
                            >
                              <ImLocation
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              Facility Location
                            </div>
                          </div>
                          <div className="col-6">
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Button
                                type="button"
                                color="info"
                                style={{ marginRight: 10 }}
                                onClick={() =>
                                  this.setState({ addFacilityLocation: true })
                                }
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon={faPen} className="text-white" style={{ marginRight: 5 }} />
                                Update
                              </Button>
                              <Form>
                                <Form.Check // prettier-ignore
                                  type="switch"
                                  id="facility-location-switch"
                                  checked={this.state.selectedData.showFacilityLocationSection}
                                  onChange={() => this.onToggleSection('showFacilityLocationSection', !this.state.selectedData.showFacilityLocationSection)}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div
                              className=" d-flex  align-items-center w-100 py-2 about"
                            >
                              <ImProfile
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              About
                            </div>
                          </div>
                          <div className="col-6">
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Button
                                type="button"
                                color="info"
                                style={{ marginRight: 46 }}
                                onClick={() =>
                                  this.setState({ addAbout: true })
                                }
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon={faPen} className="text-white" style={{ marginRight: 5 }} />
                                Update
                              </Button>

                            </div>
                          </div>
                        </div>
                        <div
                          className="row uploadbtn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="col-6">
                            <div
                              className=" d-flex align-items-center w-100 py-2 contactInfo"
                            >
                              <ImAddressBook
                                className="mr-2"
                                style={{ fontSize: 18 }}
                              />
                              Contact Info
                            </div>
                          </div>
                          <div className="col-6">
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "right",
                              justifyContent: "end",
                              flexWrap: "wrap",
                            }}>
                              <Button
                                type="button"
                                color="info"
                                style={{ marginRight: 46 }}
                                onClick={() =>
                                  this.setState({ addContactInfo: true })
                                }
                                className="btn-md"
                              >
                                <FontAwesomeIcon icon={faPen} className="text-white" style={{ marginRight: 5 }} />
                                Update
                              </Button>

                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  {(this.state.uploadPhoto ||
                    this.state.uploadResume ||
                    this.state.uploadVideo) && (
                      <>
                        {this.state.uploadVideo && (
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-8">
                              <FormGroup>
                                <FormLabel for="title">Video Title</FormLabel>
                                <Input
                                  type="text"
                                  value={this.state.videoTitle}
                                  name="videoTitle"
                                  id="videoTitle"
                                  className="has-input"
                                  placeholder="Video Title"
                                  onChange={(e) => {
                                    this.setState({ videoTitle: e.target.value });
                                  }}
                                />
                              </FormGroup>
                            </div>
                          </div>
                        )}
                        <div className="row" style={{ alignItems: "center" }}>
                          <div className="col-12">
                            <label className="btn btn-default p-0">
                              <input
                                type="file"
                                accept={this.getAcceptedTypes()}
                                onChange={(event) => this.selectFile(event)}
                              />
                            </label>
                          </div>
                        </div>
                        {this.state.uploadPhoto && (
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-4">
                              <div>
                                <img
                                  src={this.state.previewImage}
                                  width="50%"
                                  height="50%"
                                ></img>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                </>
              ) : (
                <>
                  <p>
                    Name: {this.state.selectedData.professionalName}
                    <br />
                    Email: {this.state.selectedData.professionalEmail}
                    <br />
                    Phone: {this.state.selectedData.professionalPhone}
                    <br />
                    Address: {this.state.selectedData.professionalAddress}
                    <br />
                    Course: {this.state.selectedData.field}
                    <br />
                    Provider: {this.state.selectedData.provider}
                    <br />
                    Issue Date:{" "}
                    {this.state.selectedData.issueDate && (
                      <Moment format="MMM DD, YYYY">
                        {this.state.selectedData.issueDate}
                      </Moment>
                    )}
                    <br />
                    Expiration Date (optional):{" "}
                    {this.state.selectedData.expiryDate && (
                      <Moment format="MMM DD, YYYY">
                        {this.state.selectedData.expiryDate}
                      </Moment>
                    )}
                    <br />
                    CECs: {this.state.selectedData.CECs}
                  </p>
                </>
              )}
            </Modal.Body>
            {this.state.uploadModal ? (
              <Modal.Footer>
                <>
                  {(this.state.uploadPhoto ||
                    this.state.uploadResume ||
                    this.state.uploadVideo) && (
                      <>
                        {this.state.uploadVideo &&
                          <Button color="warning"
                            onClick={() =>
                              this.setState({ showUploadedVideos: true })
                            }
                          >
                            Video Uploaded Videos
                          </Button>
                        }
                        <Button
                          color="primary"
                          onClick={() =>
                            this.upload(
                              this.state.uploadPhoto
                                ? "photo"
                                : this.state.uploadResume
                                  ? "resume"
                                  : "video"
                            )
                          }
                        >
                          Upload
                        </Button>
                      </>
                    )}
                </>
              </Modal.Footer>
            ) : (
              <Modal.Footer>
                <Button
                  color="primary"
                  value={JSON.stringify(this.state.selectedData)}
                  style={{
                    visibility: this.state.view ? "hidden" : "visible",
                  }}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_CLIENT_URL}/certificate/${this.state.selectedData.issuedTransactionId}`,
                      "_blank"
                    )
                  }
                >
                  View Certificate Page
                </Button>
              </Modal.Footer>
            )}
          </Modal>
          <Modal
            show={this.state.revokeShow}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Revoke Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Name: {this.state.selectedData.professionalName}
                <br />
                Course: {this.state.selectedData.field}
                <br />
                Provider: {this.state.selectedData.provider}
                <br />
                Issue Date:{" "}
                {this.state.selectedData.issueDate && (
                  <Moment format="MMM DD, YYYY">
                    {this.state.selectedData.issueDate}
                  </Moment>
                )}
                <br />
                Expiration Date (optional):{" "}
                {this.state.selectedData.expiryDate && (
                  <Moment format="MMM DD, YYYY">
                    {this.state.selectedData.expiryDate}
                  </Moment>
                )}
                <br />
                CECs: {this.state.selectedData.CECs}
              </p>

              <label for="w3review">Request Reason</label>
              <textarea name="taname" id="taid" wrap="soft"></textarea>
            </Modal.Body>

            <Modal.Footer>
              <Button color="secondary" onClick={this.handleClose}>
                Close
              </Button>

              <Button
                color="primary"
                style={{ visibility: this.state.view ? "hidden" : "visible" }}
                onClick={() => this.revokeCertificate()}
              >
                Create Revoke Request
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal
            show={this.state.showVisitCount}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Visit Count</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.selectedData.visits && this.state.selectedData.visits.length ?
                this.state.selectedData.visits.map((obj, index) => (
                  <p key={index}><Moment key={index} format="MMM, YYYY">{obj.date}</Moment>  <span style={{ float: 'right' }}><Badge variant="primary">{obj.count}</Badge></span>  </p>
                )) : <p>No count found</p>}
            </Modal.Body>

            <Modal.Footer>
              Total Count <Badge variant="primary" style={{ marginLeft: 10 }}>{this.state.totalVisitCount}</Badge>
            </Modal.Footer>
          </Modal>

          <Modal
            size="lg"
            show={this.state.showUploadedVideos}
            onHide={() => {
              this.setState({ showUploadedVideos: false });
            }}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Videos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.selectedData.videos && this.state.selectedData.videos.length ?
                this.state.selectedData.videos.map((obj, index) => (
                  <div
                    className="row video-list"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="col-9">
                      <div
                        className="d-flex align-items-center w-100 py-2"
                      >
                        <ImVideoCamera
                          className="mr-2"
                          style={{ fontSize: 20 }}
                        />
                        {obj.title}
                      </div>
                    </div>
                    <div className="col-3">
                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "right",
                        justifyContent: "end",
                        flexWrap: "wrap",
                      }}>
                        <Button
                          color="primary"
                          onClick={() =>
                            window.open(
                              `${obj.url}`,
                              "_blank"
                            )
                          }
                        >
                          Play Video
                        </Button>
                      </div>
                    </div>
                  </div>
                )) : <p>No video found</p>}
            </Modal.Body>

            <Modal.Footer>
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({ showUploadedVideos: false });
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="lg"
            show={this.state.addTestimonial}
            onHide={() => {
              this.setState({ addTestimonial: false });
            }}
            backdrop="static"
            keyboard={false}
          >
            <Form
              ref={this.myRef}
              validated={this.state.validated}
              onSubmit={this.onSubmitTestimonial}
            >
              <Modal.Header closeButton>
                <Modal.Title>Testimonials</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row
                  className="justify-content-center pt-4 pb-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Col xs={12} sm={8} md={9} lg={9}>
                    <FormGroup className="mb-3" controlId="testimonial">
                      <FormControl
                        as="textarea"
                        rows={3}
                        required
                        maxLength="1000"
                        placeholder="Enter testimonial"
                      />
                      <Form.Control.Feedback type="invalid">
                        Testimonial is required.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={4} md={3} lg={3} className="mb-3">
                    <Button
                      color="primary"
                      type="submit"
                      style={{ float: "right" }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
                {this.state.selectedData?.testimonials &&
                  this.state.selectedData?.testimonials?.length ? (
                  <Row className="justify-content-center pt-2 pb-2">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ maxHeight: 250, overflowX: "auto" }}
                    >
                      <ListGroup>
                        {this.state.selectedData.testimonials.map(
                          (testimonial, index) => (
                            <>
                              <ListGroup.Item key={`service-${index}`}>
                                <Row className="justify-content-center pt-2 pb-2">
                                  <Col xs={8} sm={8} md={8} lg={10}>
                                    {testimonial}
                                  </Col>
                                  <Col xs={4} sm={4} md={4} lg={2}>
                                    <Button
                                      color="danger"
                                      type="button"
                                      style={{ float: "right", marginLeft: 5 }}
                                      onClick={() => {
                                        this.onDeleteTestimonial(
                                          testimonial,
                                          index
                                        );
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-white"
                                      />
                                    </Button>
                                    <Button
                                      color="primary"
                                      type="button"
                                      style={{ float: "right" }}
                                      onClick={() => {
                                        this.onUpdateTestimonial(
                                          testimonial,
                                          index
                                        );
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPen}
                                        className="text-white"
                                      />
                                    </Button>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                            </>
                          )
                        )}
                      </ListGroup>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState({ addTestimonial: false });
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            show={this.state.updateTestimonial}
            onHide={() => {
              this.setState({ updateTestimonial: false });
            }}
            backdrop="static"
            keyboard={false}
          >
            <Form
              ref={this.myRef}
              validated={this.state.validated}
              onSubmit={this.onSubmitUpdateTestimonial}
            >
              <Modal.Header closeButton>
                <Modal.Title>Update Testimonial</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="justify-content-center pt-4 pb-3">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FormGroup className="mb-3" controlId="updateTestimonial">
                      <FormControl
                        as="textarea"
                        rows={3}
                        required
                        maxLength="1000"
                        placeholder="Enter testimonial"
                        defaultValue={
                          this.state.selectedTestimonial.testimonial || ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Testimonial is required.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState({ updateTestimonial: false });
                  }}
                >
                  Close
                </Button>

                <Button color="primary" type="submit">
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            show={this.state.addSocialMediaLink}
            onHide={() => {
              this.setState({ addSocialMediaLink: false });
            }}
            backdrop="static"
            keyboard={false}
          >
            <Formik
              initialValues={this.state.initialSocialLinkValues}
              validate={(values) => this.validateSocialLinks(values)}
              onSubmit={(values, { setSubmitting }) =>
                this.handleSubmitLinks(values, setSubmitting)
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form ref={this.myRef} noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>Social Media links</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="justify-content-center pt-4 pb-3">
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <FormGroup
                          className="mb-3"
                          controlId="socialFacebookURL"
                        >
                          <FormLabel>Facebook URL</FormLabel>
                          <FormControl
                            placeholder="Enter facebook URL"
                            name="socialFacebookURL"
                            value={values.socialFacebookURL}
                            onChange={handleChange}
                            isValid={
                              touched.socialFacebookURL &&
                              !errors.socialFacebookURL
                            }
                          />
                          {errors && (
                            <p style={{ color: "red" }}>
                              {errors.socialFacebookURL}
                            </p>
                          )}
                          <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup
                          className="mb-3"
                          controlId="socialTwitterURL"
                        >
                          <FormLabel>Twitter URL</FormLabel>
                          <FormControl
                            placeholder="Enter Twitter URL"
                            name="socialTwitterURL"
                            value={values.socialTwitterURL}
                            onChange={handleChange}
                            isValid={
                              touched.socialTwitterURL &&
                              !errors.socialTwitterURL
                            }
                          />
                          {errors && (
                            <p style={{ color: "red" }}>
                              {errors.socialTwitterURL}
                            </p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid URL.
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup
                          className="mb-3"
                          controlId="socialGooglePlusURL"
                        >
                          <FormLabel>Google+ URL</FormLabel>
                          <FormControl
                            placeholder="Enter Google+ URL"
                            name="socialGooglePlusURL"
                            value={values.socialGooglePlusURL}
                            onChange={handleChange}
                            isValid={
                              touched.socialGooglePlusURL &&
                              !errors.socialGooglePlusURL
                            }
                          />
                          {errors && (
                            <p style={{ color: "red" }}>
                              {errors.socialGooglePlusURL}
                            </p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid URL.
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup
                          className="mb-3"
                          controlId="socialPInterestURL"
                        >
                          <FormLabel>PInterest URL</FormLabel>
                          <FormControl
                            placeholder="Enter Pinterest URL"
                            name="socialPInterestURL"
                            value={values.socialPInterestURL}
                            onChange={handleChange}
                            isValid={
                              touched.socialPInterestURL &&
                              !errors.socialPInterestURL
                            }
                          />
                          {errors && (
                            <p style={{ color: "red" }}>
                              {errors.socialPInterestURL}
                            </p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid URL.
                          </Form.Control.Feedback>
                        </FormGroup>

                        <FormGroup
                          className="mb-3"
                          controlId="socialLinkedInURL"
                        >
                          <FormLabel>LinkedIn URL</FormLabel>
                          <FormControl
                            placeholder="Enter Linkedin URL"
                            name="socialLinkedInURL"
                            value={values.socialLinkedInURL}
                            onChange={handleChange}
                            isValid={
                              touched.socialLinkedInURL &&
                              !errors.socialLinkedInURL
                            }
                          />
                          {errors && (
                            <p style={{ color: "red" }}>
                              {errors.socialLinkedInURL}
                            </p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid URL.
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup
                          className="mb-3"
                          controlId="socialInstagramURL"
                        >
                          <FormLabel>Instagram URL</FormLabel>
                          <FormControl
                            placeholder="Enter Linkedin URL"
                            name="socialInstagramURL"
                            value={values.socialInstagramURL}
                            onChange={handleChange}
                            isValid={
                              touched.socialInstagramURL &&
                              !errors.socialInstagramURL
                            }
                          />
                          {errors && (
                            <p style={{ color: "red" }}>
                              {errors.socialInstagramURL}
                            </p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid URL.
                          </Form.Control.Feedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({ addSocialMediaLink: false });
                      }}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal
            size="lg"
            show={this.state.addService}
            onHide={() => {
              this.setState({ addService: false });
            }}
            backdrop="static"
            keyboard={false}
          >
            <Form
              ref={this.myRef}
              validated={this.state.validated}
              onSubmit={this.onSubmitService}
            >
              <Modal.Header closeButton>
                <Modal.Title>Services</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="justify-content-center pt-2 pb-2">
                  <Col xs={12} sm={8} md={9} lg={9}>
                    <FormGroup className="mb-3" controlId="service">
                      <FormControl
                        type="text"
                        required
                        placeholder="Enter new service"
                        maxLength="1000"
                      />
                      <Form.Control.Feedback type="invalid">
                        Service is required.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={4} md={3} lg={3}>
                    <Button
                      color="primary"
                      type="submit"
                      style={{ float: "right" }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
                {this.state.selectedData?.services &&
                  this.state.selectedData?.services?.length ? (
                  <Row className="justify-content-center pt-2 pb-2">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ maxHeight: 250, overflowX: "auto" }}
                    >
                      <ListGroup>
                        {this.state.selectedData.services.map(
                          (service, index) => (
                            <>
                              <ListGroup.Item key={`service-${index}`}>
                                <Row className="justify-content-center pt-2 pb-2">
                                  <Col xs={8} sm={8} md={8} lg={10}>
                                    {service}
                                  </Col>
                                  <Col xs={4} sm={4} md={4} lg={2}>
                                    <Button
                                      color="danger"
                                      type="button"
                                      style={{ float: "right", marginLeft: 5 }}
                                      onClick={() => {
                                        this.onDeleteService(service, index);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-white"
                                      />
                                    </Button>
                                    <Button
                                      color="primary"
                                      type="button"
                                      style={{ float: "right" }}
                                      onClick={() => {
                                        this.onUpdateService(service, index);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPen}
                                        className="text-white"
                                      />
                                    </Button>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                            </>
                          )
                        )}
                      </ListGroup>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState({ addService: false });
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            show={this.state.updateService}
            onHide={() => {
              this.setState({ updateService: false });
            }}
            backdrop="static"
            keyboard={false}
          >
            <Form
              ref={this.myRef}
              validated={this.state.validated}
              onSubmit={this.onSubmitUpdateService}
            >
              <Modal.Header closeButton>
                <Modal.Title>Update Service</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="justify-content-center pt-4 pb-3">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FormGroup className="mb-3" controlId="updateService">
                      <FormControl
                        type="text"
                        required
                        placeholder="Enter service"
                        maxLength="1000"
                        defaultValue={this.state.selectedService.service || ""}
                      />
                      <Form.Control.Feedback type="invalid">
                        Service is required.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState({ updateService: false });
                  }}
                >
                  Close
                </Button>

                <Button color="primary" type="submit">
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            show={this.state.addAbout}
            onHide={() => {
              this.setState({ addAbout: false });
            }}
            backdrop="static"
            keyboard={false}
          >
            <Form
              ref={this.myRef}
              validated={this.state.validated}
              onSubmit={this.onSubmitAbout}
            >
              <Modal.Header closeButton>
                <Modal.Title>About</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="justify-content-center pt-4 pb-3">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FormGroup className="mb-3" controlId="about">
                      <FormControl
                        type="text"
                        required
                        placeholder="Enter about"
                        defaultValue={this.state.selectedData.about || ""}
                        as="textarea"
                        style={{ minHeight: "200px" }}
                      />
                      <Form.Control.Feedback type="invalid">
                        About is required.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState({ addAbout: false });
                  }}
                >
                  Close
                </Button>

                <Button color="primary" type="submit">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            show={this.state.addFacilityLocation}
            onHide={() => {
              this.setState({ addFacilityLocation: false });
            }}
            backdrop="static"
            keyboard={false}
          >
            <Form
              ref={this.myRef}
              validated={this.state.validated}
              onSubmit={this.onSubmitFacilityLocation}
            >
              <Modal.Header closeButton>
                <Modal.Title>Facility Location</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="justify-content-center pt-4 pb-3">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FormGroup className="mb-3" controlId="facilityName">
                      <FormLabel>Facility Name</FormLabel>
                      <FormControl
                        placeholder="Enter facility name"
                        defaultValue={
                          this.state.selectedData.facilityName || ""
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid facility name.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FormGroup className="mb-3" controlId="facilityAddress">
                      <FormLabel>Address</FormLabel>
                      <FormControl
                        placeholder="Enter address"
                        defaultValue={
                          this.state.selectedData.facilityAddress || ""
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid address.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup className="mb-3" controlId="facilityCity">
                      <FormLabel>City</FormLabel>
                      <FormControl
                        placeholder="Enter city"
                        defaultValue={
                          this.state.selectedData.facilityCity || ""
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid city.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup className="mb-3" controlId="facilityState">
                      <FormLabel>State</FormLabel>
                      <FormControl
                        placeholder="Enter state"
                        defaultValue={
                          this.state.selectedData.facilityState || ""
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid state.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup className="mb-3" controlId="facilityZipCode">
                      <FormLabel>Zip code</FormLabel>
                      <FormControl
                        placeholder="Enter zip code"
                        defaultValue={
                          this.state.selectedData.facilityZipCode || ""
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid zip code.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <FormGroup className="mb-3" controlId="facilityCountry">
                      <FormLabel>Country</FormLabel>
                      <FormControl
                        placeholder="Enter country"
                        defaultValue={
                          this.state.selectedData.facilityCountry || ""
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid country.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FormGroup className="mb-3" controlId="facilityWorkEmail">
                      <FormLabel>Work Email</FormLabel>
                      <FormControl
                        placeholder="Enter work email"
                        defaultValue={
                          this.state.selectedData.facilityWorkEmail || ""
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid work email.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FormGroup className="mb-3" controlId="facilityPhoneNumber">
                      <FormLabel>Phone Number</FormLabel>
                      <FormControl
                        placeholder="Enter phone number"
                        defaultValue={
                          this.state.selectedData.facilityPhoneNumber || ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid phone.
                      </Form.Control.Feedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  color="secondary"
                  onClick={() => {
                    this.setState({ addFacilityLocation: false });
                  }}
                >
                  Close
                </Button>

                <Button color="primary" type="submit">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            show={this.state.addContactInfo}
            onHide={() => {
              this.setState({ addContactInfo: false });
            }}
            backdrop="static"
            keyboard={false}
          >
            <Formik
              initialValues={this.state.initialContactInfoValues}
              validate={(values) => this.validateContactInfo(values)}
              onSubmit={(values, { setSubmitting }) =>
                this.handleSubmitContactInfo(values, setSubmitting)
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form ref={this.myRef} noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>Contact Info</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="justify-content-center pt-4 pb-3">
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <FormGroup
                          className="mb-3"
                          controlId="professionalEmail"
                        >
                          <FormLabel>Email</FormLabel>
                          <FormControl
                            placeholder="Enter email address"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                          />
                          {errors && (
                            <p style={{ color: "red" }}>{errors.email}</p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide an email.
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup
                          className="mb-3"
                          controlId="professionalPhone"
                        >
                          <FormLabel>Phone</FormLabel>
                          <FormControl
                            placeholder="Enter phone number"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            isValid={touched.phone && !errors.phone}
                          />
                          {errors && (
                            <p style={{ color: "red" }}>{errors.phone}</p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a phone.
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup
                          className="mb-3"
                          controlId="professionalAddress"
                        >
                          <FormLabel>Address</FormLabel>
                          <SearchAddress
                            onChange={(value) => {
                              values.address = value.address;
                            }}
                            name="address"
                            autoFocus={true}
                            onSelect={(value) => {
                              console.log(value);
                              values.lng = value.lng;
                              values.lat = value.lat;
                              values.address = value.address;
                            }}
                            value={values.address}
                          />
                          {/* <FormControl
                            placeholder="Enter address number"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            isValid={touched.address && !errors.address}
                          /> */}
                          {errors && (
                            <p style={{ color: "red" }}>{errors.address}</p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide an address.
                          </Form.Control.Feedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      color="secondary"
                      onClick={() => {
                        this.setState({ addContactInfo: false });
                      }}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      </>
    );
  }
}

export default IssuedCertificates;
