import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faEdit,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faCopy,
  faCheckSquare,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";

import SubMenu from "./SubMenu";

const SideBar = ({ isOpen, toggle }) => (
  <div className={classNames("sidebar", { "is-open": isOpen })}>
    <div className="sidebar-header">
      <span color="info" onClick={toggle} style={{ color: "#fff" }}>
        &times;
      </span>
      <h3>The Ab Lab Certificate(s) Dashboard</h3>
    </div>
    <div className="side-menu">
      <Nav vertical className="list-unstyled pb-3">
        <p>Activities</p>
        <NavItem>
          <NavLink tag={Link} to={"/createCertificate"}>
            <FontAwesomeIcon icon={faCopy} className="mr-2" />
            Create a Certificate
          </NavLink>
        </NavItem>
        <SubMenu title="Review Certificate" icon={faEdit} items={submenus[0]} />     
        <NavItem>
          <NavLink tag={Link} to={"/revoked"}>
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
            Revoked Certificates
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to={"/issuedCertificates"}>
            <FontAwesomeIcon icon={faCopy} className="mr-2" />
            Issued Certificates
          </NavLink>
        </NavItem> 
      </Nav>
    </div>
  </div>
);

const submenus = [
  [
    {
      title: "Issue Certificates",
      target: "reviewCertificate",
      icon: faCheckSquare
    },
    {
      title: "Revoke Certificates",
      target: "revokeCertificate",
      icon: faUndo
     
    },
  ],
  [
    {
      title: "Page 1",
      target: "Page-1",
    },
    {
      title: "Page 2",
      target: "Page-2",
    },
  ],
];

export default SideBar;
