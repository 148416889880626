import React, { useState, useRef } from "react";
import request from "../Server";
import {
  Form,
  FormLabel,
  FormControl,
  FormGroup,
  FormCheck,
  Card,
  Button,
} from "react-bootstrap";
import { Row, Col, Alert } from "reactstrap";
import * as GeneralFunctions from "../GeneralFunctions";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "../utils/constants";
import { Formik } from "formik";

const initialCreateCertificateValues = {
  formName: "",
  formEmail: "",
  formPhone: "",
  formAddress: "",
  formCourse: "",
  formGridProvider: "",
  formIssueDate: "",
  formExpiryDate: "",
  formCECs: "",
  formGridCheckbox: "",
};

class CreateCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      emailValidater: "",
      passwordValidater: "",
      validated: false,
      accountAddress: null,
      Id: null,
      note: false,
      initialValues: initialCreateCertificateValues,
    };
    this.myRef = React.createRef();
  }

  validate = (values) => {
    const errors = {};
    const emailregex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneregex =
      /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/g;

    if (values.formEmail && !emailregex.test(values.formEmail)) {
      errors.formEmail = "Invalid email address";
    }

    if (values.formPhone && !phoneregex.test(values.formPhone)) {
      errors.formPhone = "Invalid phone number";
    }

    if (
      values.formExpiryDate &&
      new Date(values.formIssueDate) > new Date(values.formExpiryDate)
    ) {
      errors.formExpiryDate =
        "Invalid expiry date, expiry date should be greater than issue date";
    }

    if (!values.formAddress) {
      errors.formAddress = "Address required";
    }

    if (!values.formPhone) {
      errors.formPhone = "Phone required";
    }

    if (!values.formEmail) {
      errors.formEmail = "Email required";
    }

    if (!values.formCourse) {
      errors.formCourse = "Course required";
    }
    if (!values.formGridProvider) {
      errors.formGridProvider = "Course Provider required";
    }
    if (!values.formIssueDate) {
      errors.formIssueDate = "Issue Date required";
    }

    if (!values.formCECs) {
      errors.formCECs = "CEC required";
    }

    if (!values.formGridCheckbox) {
      errors.formGridCheckbox = "please check the checkbox";
    }

    return errors;
  };

  handleSubmit = (values, setSubmitting) => {};

  onSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    this.setState({ note: true });
    window.scrollTo(0, this.myRef.current.offsetTop);
    const user = GeneralFunctions.getAuthUser();
    const base = `/admin/createCertificate`;
    request({
      url: base,
      method: "POST",
      data: {
        professionalName: form.formName.value,
        professionalEmail: form.formEmail.value,
        professionalPhone: form.formPhone.value,
        professionalAddress: form.formAddress.value,
        field: form.formCourse.value,
        provider: form.formGridProvider.value,
        user: user,
        issueDate: form.formIssueDate.value,
        expiryDate: form.formExpiryDate.value,
        CECs: form.formCECs.value,
      },
    }).then(async (response) => {
      const msgParams = [
        {
          type: "string", // Any valid solidity type
          name: "NAME:", // Any string label you want
          value: form.formName.value, // The value to sign
        },
        {
          type: "string",
          name: "Field:",
          value: form.formCourse.value,
        },
        {
          type: "string",
          name: "Type of Certificate:",
          value: "CED",
        },
        {
          type: "string",
          name: "Provider:",
          value: form.formGridProvider.value,
        },
        {
          type: "string",
          name: "Issue Date:",
          value: form.formIssueDate.value,
        },
      ];

      const account = GeneralFunctions.getWeb3Account();
      this.setState({
        accountAddress: await account,
        response: await response,
      });
      try {
        const res = await GeneralFunctions.signMsg(
          msgParams,
          this.state.accountAddress
        );
        if (res != null) {
          await this.signCertificate(
            this.state.response?.certificate?._id,
            await res
          );
          this.notificationSystem.addNotification({
            message: "Certificate Created",
            level: "success",
          });
          form.reset();
          this.setState({ note: false });
        }
      } catch (error) {
        const response = await request({
          url: "admin/deleteCertificate",
          method: "POST",
          data: {
            certificateId: this.state.response?.certificate?._id,
            user: user,
          },
        });
        this.setState({ note: false });
        this.notificationSystem.addNotification({
          message: "Certificate Not signed  ",
          level: "error",
        });
      }
    });
  };

  signCertificate = async (certificateID, signedBy) => {
    const user = GeneralFunctions.getAuthUser();
    const base = `/admin/signCertificate`;
    try {
      const response = await request({
        url: base,
        method: "POST",
        data: { certificateId: certificateID, signedBy: signedBy, user: user },
      });
    } catch (error) {
      alert(error);
      throw Error("ERRROR");
    }
  };
  render() {
    return (
      <>
        {this.state.note ? (
          <Alert color="danger">
            Do not move away from this page before signing in metamask popup
          </Alert>
        ) : (
          <></>
        )}
        <div style={{ display: "block" }}>
          <Card className="card-main">
            <NotificationSystem
              dismissible={false}
              ref={(notificationSystem) =>
                (this.notificationSystem = notificationSystem)
              }
              style={NOTIFICATION_SYSTEM_STYLE}
            />
            <Card.Body>
              <Card.Title className="text-center">
                Create a Certificate
              </Card.Title>
              <Row className="justify-content-center pt-4 pb-3">
                <Col xs={12} sm={12} md={10} lg={10}>
                  <Formik
                    initialValues={this.state.initialValues}
                    validate={(values) => this.validate(values)}
                    onSubmit={(values, { setSubmitting }) =>
                      this.handleSubmit(values, setSubmitting)
                    }
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <Form
                        ref={this.myRef}
                        validated={this.state.validated}
                        onSubmit={this.onSubmit}
                      >
                        <FormGroup className="mb-3" controlId="formName">
                          <FormLabel>Name</FormLabel>
                          <FormControl
                            placeholder="Enter Full Name"
                            name="formName"
                            value={values.formName}
                            onChange={handleChange}
                            isValid={touched.formName && !errors.formName}
                          />
                          {errors && (
                            <p style={{ color: "red" }}>{errors.formName}</p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a Name.
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup className="mb-3" controlId="formEmail">
                          <FormLabel>Email</FormLabel>
                          <FormControl
                            placeholder="Enter email"
                            name="formEmail"
                            value={values.formEmail}
                            onChange={handleChange}
                            isValid={touched.formEmail && !errors.formEmail}
                          />
                          {errors && (
                            <p style={{ color: "red" }}>{errors.formEmail}</p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid email.
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup className="mb-3" controlId="formPhone">
                          <FormLabel>Phone</FormLabel>
                          <FormControl
                            placeholder="Enter phone number"
                            name="formPhone"
                            value={values.formPhone}
                            onChange={handleChange}
                            isValid={touched.formPhone && !errors.formPhone}
                          />
                          {errors && (
                            <p style={{ color: "red" }}>{errors.formPhone}</p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a Phone.
                          </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup className="mb-3" controlId="formAddress">
                          <FormLabel>Address</FormLabel>
                          <FormControl
                            placeholder="Enter Address"
                            name="formAddress"
                            value={values.formAddress}
                            onChange={handleChange}
                            isValid={touched.formAddress && !errors.formAddress}
                          />
                          {errors && (
                            <p style={{ color: "red" }}>{errors.formAddress}</p>
                          )}
                        </FormGroup>

                        <FormGroup className="mb-3" controlId="formCourse">
                          <FormLabel>Course</FormLabel>
                          <FormControl
                            name="formCourse"
                            value={values.formCourse}
                            onChange={handleChange}
                            isValid={touched.formCourse && !errors.formCourse}
                          />
                          {errors && (
                            <p style={{ color: "red" }}>{errors.formCourse}</p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a Course.
                          </Form.Control.Feedback>
                        </FormGroup>

                        <FormGroup
                          className="mb-3"
                          controlId="formGridProvider"
                        >
                          <FormLabel>Provider</FormLabel>
                          <Form.Control
                            name="formGridProvider"
                            value={values.formGridProvider}
                            onChange={handleChange}
                            isValid={
                              touched.formGridProvider &&
                              !errors.formGridProvider
                            }
                          />
                          {errors && (
                            <p style={{ color: "red" }}>
                              {errors.formGridProvider}
                            </p>
                          )}
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Provider.
                          </Form.Control.Feedback>
                        </FormGroup>
                        <Row className="mb-3">
                          <FormGroup as={Col} controlId="formIssueDate">
                            <FormLabel>Issue Date</FormLabel>
                            <FormControl
                              type="Date"
                              name="formIssueDate"
                              value={values.formIssueDate}
                              onChange={handleChange}
                              isValid={
                                touched.formIssueDate && !errors.formIssueDate
                              }
                            />
                            {errors && (
                              <p style={{ color: "red" }}>
                                {errors.formIssueDate}
                              </p>
                            )}
                            <FormControl.Feedback type="invalid">
                              Please provide a valid Date.
                            </FormControl.Feedback>
                          </FormGroup>
                        </Row>

                        <Row className="mb-3">
                          <FormGroup as={Col} controlId="formExpiryDate">
                            <FormLabel>Expiration Date (optional)</FormLabel>
                            <FormControl
                              type="Date"
                              name="formExpiryDate"
                              value={values.formExpiryDate}
                              onChange={handleChange}
                              isValid={
                                touched.formExpiryDate && !errors.formExpiryDate
                              }
                            />
                            {errors && (
                              <p style={{ color: "red" }}>
                                {errors.formExpiryDate}
                              </p>
                            )}
                            <FormControl.Feedback type="invalid">
                              Please provide a valid Date.
                            </FormControl.Feedback>
                          </FormGroup>
                        </Row>
                        <Row className="mb-3">
                          <FormGroup as={Col} controlId="formCECs">
                            <FormLabel>CECs</FormLabel>
                            <FormControl
                              type="text"
                              name="formCECs"
                              value={values.formCECs}
                              onChange={handleChange}
                              isValid={touched.formCECs && !errors.formCECs}
                            />
                            {errors && (
                              <p style={{ color: "red" }}>{errors.formCECs}</p>
                            )}
                            <FormControl.Feedback type="invalid">
                              Please provide a valid CECs.
                            </FormControl.Feedback>
                          </FormGroup>
                        </Row>

                        <FormGroup className="mb-3" id="formGridCheckbox">
                          <FormCheck
                            required
                            label="Agree to terms and conditions"
                            feedback="You must agree before submitting."
                          />
                        </FormGroup>
                        <Row className="mt-2">
                          <Col className="text-center">
                            <Button
                              type="submit"
                              value="Submit"
                              className="btn btn-primary"
                            >
                              submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

export default CreateCertificate;
