import React from 'react'
import '../../styles/components/Header.css';
import { useState } from "react";
import { Redirect } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";

import SideBar from "./sidebar/SideBar";
import Disp from "./content/disp";
import Login from '../../pages/Login';
import * as GeneralFunctions from '../../GeneralFunctions';

const Header = () => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const authUser = GeneralFunctions.getAuthUser();

  return (
    !authUser ? <Redirect to="/login"></Redirect> : <div className="App wrapper">
      <main className="cr-app bg-light">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Disp toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
      </main>
    </div>
  );
};

export default Header;