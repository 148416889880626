import React, { useState, useMemo, useEffect } from "react";
import request from "../Server";
import { Link } from "react-router-dom";
import Table from "./Table";
import styled from "styled-components";
import * as GeneralFunctions from "../GeneralFunctions";
import ReactTable, { useTable } from "react-table";
import { Modal, ProgressBar, Card } from "react-bootstrap";
import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Progress,
} from "reactstrap";
import Moment from "react-moment";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/style.scss";
import PageSpinner from "../components/PageSpinner";

class Revoked extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      accountAddress: null,
      pageData: [],
      showLoader: true,
      searchInput: "",
      show: false,
      signedBy: null,
      msgParams: null,
      selectedData: [],
    };
  }

  filterById(jsonObject, id) {
    return jsonObject.filter(function (jsonObject) {
      return jsonObject["_id"] == id;
    })[0];
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = (event) => {
    const selectedObject = this.filterById(this.state.data, event.target.value);

    this.setState({ show: true, selectedData: selectedObject });
  };

  getData = async () => {
    this.setState({ showLoader: true });
    const base = "admin/certificate/search";
    const response = await request({
      url: base,
      method: "POST",
      data: { status: "revoked" },
    });

    this.setState({
      data: JSON.parse(JSON.stringify(response.certificates)),
      pageData: JSON.parse(JSON.stringify(response.certificates)),
      showLoader: false,
    });
  };

  componentDidMount() {
    this.getData();
  }

  handleRevoke = (event) => {
    this.revokeCertificate(event.target.value);
  };

  revokeCertificate = async (certificateID) => {
    const user = GeneralFunctions.getAuthUser();
    const base = `/admin/revokeRequest`;
    try {
      const response = await request({
        url: base,
        method: "POST",
        data: { certificateId: certificateID, user: user },
      });
    } catch (error) {
      alert(error);
      throw Error("ERRROR");
    }
  };

  globalSearch = (searchInput) => {
    let filteredData = this.state.pageData.filter((value) => {
      return (
        value.professionalName
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        value.issuerId.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  render() {
    const columns = [
      {
        Header: "Name",
        accessor: "professionalName",
      },
      {
        Header: "Revoked By",
        accessor: "issuerId",
      },
      {
        Header: "Action",
        accessor: "_id",
        Cell: ({ cell }) => {
          // alert(id)
          return (
            <>
              <Button
                type="button"
                value={cell.row.values._id}
                color="info"
                style={{ marginRight: 15 }}
                onClick={this.handleShow}
                className="btn-sm"
              >
                View
              </Button>
            </>
          );
        },
      },
    ];
    return (
      <>
        <div>
          <Row className="mt-3">
            <Col xs={12} sm={12}>
              <Card className="card-main">
                <Card.Body>
                  <Card.Title className="text-center">
                    Revoked Certificates
                  </Card.Title>
                  <PageSpinner showLoader={this.state.showLoader} />
                  <Row className="mb-4">
                    <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={5}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="btn btn-secondary">
                            <span>
                              <FontAwesomeIcon
                                icon={faSearch}
                                className="text-white"
                              />
                            </span>
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          size="large"
                          name="searchInput"
                          onChange={(e) => {
                            if (e.target.value)
                              this.globalSearch(e.target.value);
                            else this.setState({ data: this.state.pageData });
                          }}
                          label="Search"
                          placeholder="Search Certificate No., Name, ID ..."
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Table columns={columns} data={this.state.data} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Certificate Overview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Name: {this.state.selectedData.professionalName}
                <br />
                Email: {this.state.selectedData.professionalEmail}
                <br />
                Phone: {this.state.selectedData.professionalPhone}
                <br />
                Address: {this.state.selectedData.professionalAddress}
                <br />
                Course: {this.state.selectedData.field}
                <br />
                Provider: {this.state.selectedData.provider}
                <br />
                Issue Date:{" "}
                {this.state.selectedData.issueDate && (
                  <Moment format="MMM DD, YYYY">
                    {this.state.selectedData.issueDate}
                  </Moment>
                )}
                <br />
                Expiration Date (optional):{" "}
                {this.state.selectedData.expiryDate && (
                  <Moment format="MMM DD, YYYY">
                    {this.state.selectedData.expiryDate}
                  </Moment>
                )}
                <br />
                CECs: {this.state.selectedData.CECs}
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button color="secondary" onClick={this.handleClose}>
                Close
              </Button>

              {/* <Button
                color="primary"
                value={JSON.stringify(this.state.selectedData)}
                style={{ visibility: this.state.view ? "hidden" : "visible" }}
                onClick={() => window.open("someLink", "_blank")}
              >
                View Certificate Page
              </Button> */}
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default Revoked;
