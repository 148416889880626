import React from "react";
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useBlockLayout,
  usePagination,
} from "react-table";
import { useCssUnit } from "react-table-css-unit";
import { Table } from "reactstrap";

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "center",
      display: "flex",
    },
  },
];

export default function TableData({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination,
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    (hooks) => {}
  );
  var pagesArray = [];
  for (var i = 1; i <= pageOptions.length; i++) {
      pagesArray.push(<option value={i}>{i}</option>);
  }
  return (
    <>
    <div className="row mb-3">
        <div class="col-md-12 col-lg-12 col-xl-4">
          <div class="d-flex">
            <div sm="4" class="mt-2 pr-2">
              <label class="">Shown</label>
            </div>
            <div sm="4">
              <select
                name="pageLimit"
                class="form-control"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>

            <div sm="4" class="mt-2 pl-2">
              <label class="">entries</label>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-4">
          <div class="d-flex justify-content-center">
            <div class="mt-2 pr-2">
              <label class="">
                {pageIndex + 1} of {pageOptions.length}
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-4">
          <div class="d-flex justify-content-end">
            <div sm="4" class="pr-2">
              <button
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                class="rounded-circle btn btn-secondary d-flex justify-center align-items-center"
                style={{ width: '36px', height: '36px', padding: 0 }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="24px"
                  width="24px"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ margin: '0 auto' }}
                >
                  <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
                </svg>
              </button>
            </div>
            <div sm="4">
              <select
                name="filter"
                value={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                class="form-control"
              >

                {pagesArray}
              </select>
            </div>
            <div sm="4" class="pl-2">
              <button
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                class="rounded-circle btn btn-secondary d-flex justify-center align-items-center"
                style={{ width: '36px', height: '36px', padding: 0 }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="20px"
                  width="20px"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ margin: '0 auto' }}
                >
                  <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    <Table responsive bordered>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr className="bg-primary text-white">
            {headerGroup.headers.map((column) => (
              <th>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr>
              {row.cells.map((cell) => {
                return (
                  <td>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
    </>
  );
}
