import React from 'react';
import {
    Collapse, Card, CardBody, CardHeader, Col, Row, Table, Badge, Button, Form, FormGroup, Input, Label,
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import Page from '../components/Page';

import { MdFilterList, MdKeyboardArrowUp, MdKeyboardArrowDown, MdDeleteForever, MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FiExternalLink } from 'react-icons/fi';
import request from '../Server';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../utils/constants';
import * as GeneralFunctions from '../GeneralFunctions';
import PageSpinner from '../components/PageSpinner';
import Moment from 'react-moment';

let selectedUserId = null;

class Users extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            collapseFilter: false,
            removeUserConfirmationModal: false,
            userDetailModal: false,
            userStatusId: null,
            users: [],
            filter: {
                email: "",
                status: "",
                name: "",
                type: ""
            },
            page: 1,
            limit: 10,
            totalUsers: 0,
            pages: 0,
            pageNumbers: [],
            userDetail: {}
        };
    }

    componentDidMount() {
        let permissions = GeneralFunctions.getUserPermission();
        if (!permissions.includes('users')) this.props.history.push('/issuedCertificates');
        this.getUsersDetails();
    }

    getUsersDetails = async () => {
        try {
            this.setState({ showLoader: true });
            let filter = this.state.filter;
            let page = this.state.page;
            let limit = this.state.limit;
            let response = await request({
                url: 'admin/user/search',
                method: 'POST',
                data: { filter, page, limit }
            });
            this.setState({ showLoader: false });
            this.setState({ users: response.users, totalUsers: response.totalUsers, pages: response.pages });
            let pageNumbers = [];
            for (let page = 1; page <= response.pages; page++) {
                pageNumbers.push(page);
            }
            this.setState({ pageNumbers: pageNumbers });
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    removeUser = async (id) => {
        try {
            this.setState({ showLoader: true });
            let response = await request({
                url: 'admin/user/' + id + '/removeUser',
                method: 'DELETE'
            });
            if (response) {
                this.setState({ showLoader: false });
                selectedUserId = null;
                this.notificationSystem.addNotification({
                    message: 'User removed successfully.',
                    level: 'success',
                });
                this.getUsersDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    toggleRemoveUserConfirmationModal = async (id) => {
        if (!selectedUserId && id) {
            selectedUserId = id;
        }
        this.setState({
            removeUserConfirmationModal: !this.state.removeUserConfirmationModal
        });
    }

    setUserId = async (_id) => {
        this.setState({ userStatusId: _id });
    }

    changeStatus = async (userId, value) => {
        try {
            this.setState({ showLoader: true });
            let status = value;
            let response = await request({
                url: 'admin/user/updateStatus',
                method: 'POST',
                data: { userId, status }
            });
            if (response) {
                this.setState({ showLoader: false });
                this.notificationSystem.addNotification({
                    message: 'User status updated successfully.',
                    level: 'success',
                });
                this.setState({ userStatusId: null });
                this.getUsersDetails();
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    search() {
        this.setState({ page: parseInt(1, 10) }, () => {
            this.getUsersDetails();
        });
    }

    refreshPagination = async (page, limit) => {
        this.setState({ page: parseInt(page, 10), limit: limit }, () => {
            this.getUsersDetails();
        });
    }

    getUserInfo = async (id) => {
        try {
            this.setState({ showLoader: true });
            let response = await request({
                url: 'admin/user/details/' + id,
                method: 'GET'
            });
            if (response) {
                this.setState({ showLoader: false });
                this.setState({ userDetailModal: true });
                this.setState({ userDetail: response.user });
            }
        } catch (error) {
            this.setState({ showLoader: false });
            this.notificationSystem.addNotification({
                message: error.message,
                level: 'error',
            });
        }
    }

    closeModal = async () => {
        this.setState({ userDetailModal: false })
    }

    render() {
        return (
            <div>
                <PageSpinner showLoader={this.state.showLoader} />
                <Page
                    title="Users"
                    breadcrumbs={[{ name: 'Users', active: true }]}
                >
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Card>
                                <CardHeader onClick={(event) => { this.setState({ collapseFilter: !this.state.collapseFilter }) }} style={{ cursor: 'pointer' }}>
                                    <React.Fragment>
                                        <MdFilterList style={{ fontSize: 20, marginRight: 10 }}></MdFilterList>
                                        Filter
                                        {
                                            this.state.collapseFilter ?
                                                <MdKeyboardArrowUp style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowUp>
                                                : <MdKeyboardArrowDown style={{ fontSize: 14, float: 'right' }}></MdKeyboardArrowDown>
                                        }
                                    </React.Fragment>
                                </CardHeader>
                                <Collapse isOpen={this.state.collapseFilter}>
                                    <CardBody>
                                        <Form onSubmit={(event) => { event.preventDefault(); this.search() }}>
                                            <Row>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="exampleEmail" sm={2}>
                                                            Name
                                                        </Label>
                                                        <Col sm={10}>
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                className="has-input"
                                                                placeholder="Name"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            name: event.target.value
                                                                        }
                                                                    }));
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="exampleEmail" sm={2}>
                                                            Email
                                                        </Label>
                                                        <Col sm={10}>
                                                            <Input
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                className="has-input"
                                                                placeholder="Email ID"
                                                                onChange={(event) => {
                                                                    event.persist();
                                                                    this.setState(prevState => ({
                                                                        filter: {
                                                                            ...prevState.filter,
                                                                            email: event.target.value
                                                                        }
                                                                    }));
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl={6} lg={12} md={12}>
                                                    <FormGroup row>
                                                        <Label for="exampleSelect" sm={2}>
                                                            Type
                                                        </Label>
                                                        <Col sm={10}>
                                                            <Input type="select" name="type" onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        type: event.target.value
                                                                    }
                                                                }));
                                                            }}>
                                                                <option value="">Select</option>
                                                                <option value="pinger">Pinger</option>
                                                                <option value="ponger">Ponger</option>
                                                                <option value="basic">Enthusiast</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="exampleSelect" sm={2}>
                                                            Status
                                                        </Label>
                                                        <Col sm={10}>
                                                            <Input type="select" name="status" onChange={(event) => {
                                                                event.persist();
                                                                this.setState(prevState => ({
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        status: event.target.value
                                                                    }
                                                                }));
                                                            }}>
                                                                <option value="">Select</option>
                                                                <option value="pending">Pending</option>
                                                                <option value="approved">Approved</option>
                                                                <option value="rejected">Rejected</option>
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <FormGroup className="d-flex justify-content-center w-100">
                                                    <Button style={{ "width": "30%" }} type="submit">Search</Button>
                                                </FormGroup>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="mb-3">
                                <CardHeader>
                                    <Row>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex">
                                                <div sm={4} className="mt-2 pr-2"><Label>Shown</Label></div>
                                                <div sm={4}>
                                                    <Input type="select" name="pageLimit" onChange={(event) => { this.refreshPagination(1, event.target.value) }}>
                                                        <option value={10}>10</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </Input>
                                                </div>
                                                <div sm={4} className="mt-2 pl-2"><Label>entries</Label></div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-center">
                                                <div className="mt-2 pr-2">
                                                    <Label>Showing {!this.state.totalUsers ? 0 : ((this.state.page * this.state.limit) - (this.state.limit - 1))}  to {(this.state.page * this.state.limit) > this.state.totalUsers ? this.state.totalUsers : (this.state.page * this.state.limit)} of {this.state.totalUsers}
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={12} md={12}>
                                            <div className="d-flex justify-content-end">
                                                <div sm={4} className="pr-2"><Button className="rounded-circle" disabled={this.state.page <= 1} onClick={(event) => this.refreshPagination(this.state.page - 1, this.state.limit)}><MdKeyboardArrowLeft size="20px"></MdKeyboardArrowLeft></Button></div>
                                                <div sm={4}>
                                                    <Input type="select" value={this.state.page} name="filter" onChange={(event) => { this.refreshPagination(event.target.value, this.state.limit) }}>
                                                        {
                                                            this.state.pageNumbers.map((pageNumber, index) => (
                                                                <option value={pageNumber} key={index}>{pageNumber}</option>
                                                            ))
                                                        }
                                                    </Input>
                                                </div>
                                                <div sm={4} className="pl-2"><Button className="rounded-circle" disabled={this.state.page >= this.state.pages} onClick={(event) => this.refreshPagination(this.state.page + 1, this.state.limit)}><MdKeyboardArrowRight size="20px"></MdKeyboardArrowRight></Button></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table striped hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Mobile No.</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.users && this.state.users.length > 0
                                                    ? this.state.users.map((user, index) => (
                                                        <tr key={index}>
                                                            <td><Button color="link" style={{ textAlign: 'left' }} onClick={(event) => this.getUserInfo(user._id)}>{user.firstName} {user.middleName || ''} {user.lastName || ''}</Button></td>
                                                            <td>{user.email}</td>
                                                            <td>{user.mobileNumber || ''}</td>
                                                            <td>
                                                                {
                                                                    user.accountType == 'pinger' && "Pinger"
                                                                }
                                                                {
                                                                    user.accountType == 'ponger' && "Ponger"
                                                                }
                                                                {
                                                                    user.accountType == 'basic' && "Enthusiast"
                                                                }
                                                            </td>
                                                            <td>{<Moment format="MM/DD/YYYY">{user.createdAt}</Moment>}</td>
                                                            {
                                                                this.state.userStatusId === user._id ?
                                                                    <td><Input type="select" name="status" value={user.status} onChange={(event) => { this.changeStatus(user._id, event.target.value) }} >
                                                                        <option value="">Select</option>
                                                                        <option value="approved">Approve</option>
                                                                        <option value="rejected">Reject</option>
                                                                    </Input>
                                                                    </td>
                                                                    : <td>
                                                                        {
                                                                            user.status == 'pending' &&
                                                                            <Badge color="warning" pill>{GeneralFunctions.capitalize(user.status)}</Badge>
                                                                        }
                                                                        {
                                                                            user.status == 'approved' &&
                                                                            <Badge color="success" pill>{GeneralFunctions.capitalize(user.status)}</Badge>
                                                                        }
                                                                        {
                                                                            user.status == 'rejected' &&
                                                                            <Badge color="danger" pill>{GeneralFunctions.capitalize(user.status)}</Badge>
                                                                        }
                                                                        <Button color="link" onClick={() => this.setUserId(user._id)}>Change</Button>
                                                                    </td>
                                                            }
                                                            <td><Button onClick={(event) => this.toggleRemoveUserConfirmationModal(user._id)}><MdDeleteForever size="20px"></MdDeleteForever></Button></td>
                                                        </tr>
                                                    )) :
                                                    <tr><td colSpan="7" className="text-center">No users found</td></tr>
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {
                                    this.state.removeUserConfirmationModal ?
                                        <Modal
                                            isOpen={this.state.removeUserConfirmationModal}
                                            toggle={(event) => this.toggleRemoveUserConfirmationModal()}
                                            className="modal-md">
                                            <ModalHeader toggle={(event) => this.toggleRemoveUserConfirmationModal()}></ModalHeader>
                                            <ModalBody>
                                                Are you sure you want to delete this user ?
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="secondary" onClick={(event) => this.toggleRemoveUserConfirmationModal()}>Cancel</Button>{" "}
                                                <Button color="primary" onClick={(event) => { this.toggleRemoveUserConfirmationModal(); this.removeUser(selectedUserId) }}>
                                                    Delete
                                                </Button>
                                            </ModalFooter>
                                        </Modal>
                                        : null
                                }
                                {
                                    this.state.userDetailModal &&
                                    <Modal
                                        isOpen={this.state.userDetailModal}
                                        toggle={(event) => this.closeModal()}
                                        className="modal-lg">
                                        <ModalHeader toggle={(event) => this.closeModal()}>User Details</ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <Row>
                                                        <Label sm={4}>Name :</Label>
                                                        <Label sm={8}>
                                                            {this.state.userDetail.firstName} {this.state.userDetail.middleName || ''} {this.state.userDetail.lastName || ''}
                                                        </Label>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={4}>Email :</Label>
                                                        <Label sm={8}>{this.state.userDetail.email}</Label>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={4}>Address :</Label>
                                                        <Label sm={8}>{this.state.userDetail.address || ''}</Label>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={4}>City :</Label>
                                                        <Label sm={8}>{this.state.userDetail.city || ''}</Label>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={4}>State :</Label>
                                                        <Label sm={8}>{this.state.userDetail.state || ''}</Label>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={4}>Zip code :</Label>
                                                        <Label sm={8}>{this.state.userDetail.zip || ''}</Label>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={4}>Country :</Label>
                                                        <Label sm={8}>{this.state.userDetail.country || ''}</Label>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={4}>Mobile No. :</Label>
                                                        <Label sm={8}>{this.state.userDetail.mobileNumber || ''}</Label>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={4}>Type :</Label>
                                                        <Label sm={8}>
                                                            {
                                                                this.state.userDetail.accountType == 'pinger' && "Pinger"
                                                            }
                                                            {
                                                                this.state.userDetail.accountType == 'ponger' && "Ponger"
                                                            }
                                                            {
                                                                this.state.userDetail.accountType == 'basic' && "Enthusiast"
                                                            }
                                                        </Label>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={4}>Website :</Label>
                                                        <Label sm={8}>{this.state.userDetail.website || ''}</Label>
                                                    </Row>
                                                    {
                                                        this.state.userDetail.accountType === "pinger" &&
                                                        <React.Fragment>
                                                            <Row>
                                                                <Label sm={4}>Specialty :</Label>
                                                                <Label sm={8}>{this.state.userDetail.speciality || ''}</Label>
                                                            </Row>
                                                            <Row>
                                                                <Label sm={4}>Degree :</Label>
                                                                <Label sm={8}>{this.state.userDetail.degreeName || ''}</Label>
                                                            </Row>
                                                            <Row>
                                                                <Label sm={4}>Group :</Label>
                                                                <Label sm={8}>{this.state.userDetail.groupName || ''}</Label>
                                                            </Row>
                                                            <Row>
                                                                <Label sm={4}>Certifications :</Label>
                                                                <Label sm={8}>
                                                                    <Table striped hover responsive>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Personal Trainer No</th>
                                                                                <th>Group Exercise No</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.state.userDetail.certificates.map((certificate, key) => {
                                                                                    return (
                                                                                        certificate.checked && certificate.name != 'Other' &&
                                                                                        <tr key={certificate.id}>
                                                                                            <td>{certificate.name}</td>
                                                                                            <td>{certificate.personalTrainerNo}</td>
                                                                                            <td>{certificate.groupExerciseNo}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                </Label>
                                                            </Row>
                                                            {
                                                                this.state.userDetail.otherCertificate &&
                                                                <Row>
                                                                    <Label sm={4}>Other Certification (s) :</Label>
                                                                    <Label sm={8}>{this.state.userDetail.otherCertificate || ''}</Label>
                                                                </Row>
                                                            }
                                                            <Row>
                                                                <Label sm={4}>Liability insurance company :</Label>
                                                                <Label sm={8}>{this.state.userDetail.liabilityInsuranceCompany || ''}</Label>
                                                            </Row>
                                                            <Row>
                                                                <Label sm={4}>Date of expiration :</Label>
                                                                <Label sm={8}>{this.state.userDetail.dateOfExpiration || ''}</Label>
                                                            </Row>
                                                            <Row>
                                                                <Label sm={4}>Certificate Verfication URL :</Label>
                                                                <Label sm={8}>{this.state.userDetail.certificateVerificationLink || ''}</Label>
                                                            </Row>
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        this.state.userDetail.accountType === "ponger" &&
                                                        <React.Fragment>

                                                            <Row>
                                                                <Label sm={4}>Company :</Label>
                                                                <Label sm={8}>{this.state.userDetail.companyName || ''}</Label>
                                                            </Row>
                                                        </React.Fragment>
                                                    }
                                                    {
                                                        this.state.userDetail.accountType === "pinger" &&
                                                        <Row>
                                                            <Label sm={4}>Followers :</Label>
                                                            <Label sm={8}>{this.state.userDetail.followers && this.state.userDetail.followers.length || 0}</Label>
                                                        </Row>
                                                    }
                                                    <Row>
                                                        <Label sm={4}>Date :</Label>
                                                        <Label sm={8}>{<Moment format="MM/DD/YYYY">{this.state.userDetail.createdAt}</Moment>}</Label>
                                                    </Row>
                                                    <Row>
                                                        <Label sm={4}>Status :</Label>
                                                        <Label sm={8}>
                                                            {
                                                                this.state.userDetail.status == 'pending' &&
                                                                <Badge color="warning" pill>{GeneralFunctions.capitalize(this.state.userDetail.status)}</Badge>
                                                            }
                                                            {
                                                                this.state.userDetail.status == 'approved' &&
                                                                <Badge color="success" pill>{GeneralFunctions.capitalize(this.state.userDetail.status)}</Badge>
                                                            }
                                                            {
                                                                this.state.userDetail.status == 'rejected' &&
                                                                <Badge color="danger" pill>{GeneralFunctions.capitalize(this.state.userDetail.status)}</Badge>
                                                            }
                                                        </Label>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="secondary" onClick={(event) => this.closeModal()}>Cancel</Button>
                                        </ModalFooter>
                                    </Modal>
                                }
                            </Card>
                        </Col>
                    </Row>
                    <NotificationSystem
                        dismissible={false}
                        ref={notificationSystem =>
                            (this.notificationSystem = notificationSystem)
                        }
                        style={NOTIFICATION_SYSTEM_STYLE}
                    />
                </Page>
            </div>
        );
    }
}
export default Users;
